import React, { Component } from "react";
import validatePheasantForm from "../../validation/validatePheasantForm";
import { pheasantFormDefaults } from "../../settings/pheasantFormDefaults";
import isEmpty from "../../util/isEmpty";
import centsToDollarFormat from "../../util/centsToDollarFormat";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { updateFormData, setReturnURL } from "../../actions/formActions";

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pheasantTypes: [],
      purchaseMultiples: "0",
      straightrunMultiples: 100,
      purchaseMax: "",
      purchaseMin: "",
      maleCost: "0",
      femaleCost: "0",
      straightrunCost: 280,
      totalMaleCost: "0",
      totalFemaleCost: "0",
      totalStraightrunCost: "0",
      formSubmitted: false,
      fields: {
        formName: "4-H Alberta Pheasant Purchase Form",
        pheasantType: "",
        pheasantMaleNumber: "0",
        pheasantFemaleNumber: "0",
        straightrunNumber: "0",
      },
      totalAmount: "",
      errors: {},
    };
  }

  componentDidMount() {
    //* Initially load form defaults.

    if (pheasantFormDefaults.pheasantTypes.length === 1) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          pheasantType: pheasantFormDefaults.pheasantTypes[0].itemCode,
        },
      }));
    }

    this.setState(
      {
        pheasantTypes: pheasantFormDefaults.pheasantTypes,
        purchaseMultiples: pheasantFormDefaults.formDefaults.purchaseMultiples,
        purchaseMin: pheasantFormDefaults.formDefaults.minPurchase,
        purchaseMax: pheasantFormDefaults.formDefaults.maxPurchase,
      },
      () => {
        //* Update state if values exsist.
        if (this.props.form) {
          const formData = this.props.form;
          const formFieldState = this.state.fields;

          if (
            typeof formData.formName !== "undefined" &&
            formData.formName === formFieldState.formName
          ) {
            //* Create a complete object from current state
            let newObj = {};
            Object.keys(formFieldState).forEach((item) => {
              newObj[item] = formFieldState[item];
            });

            //* Update key data if within the prop object
            Object.keys(formData).forEach((item) => {
              if (newObj.hasOwnProperty(item)) {
                newObj[item] = formData[item];
              }
            });

            //* Update the state from the updated object
            this.setState((prevState, props) => ({
              fields: newObj,
            }));
          }
        }
        setTimeout(() => {
          this.updatePheasantCost();
        }, 200);
      }
    );
  }

  //* Form functions
  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          [name]: value,
        },
      }),
      () => {
        if (this.state.formSubmitted) {
          this.validateForm();
        }
        this.updatePheasantCost();
      }
    );
  };

  updatePheasantCost = () => {
    const pheasantTypesArray = this.state.pheasantTypes;
    const pheasantTypeItemCode = this.state.fields.pheasantType;
    const purchaseMultiples = this.state.purchaseMultiples;
    const straightrunMultiples = this.state.straightrunMultiples;
    const pheasantMaleNumber = this.state.fields.pheasantMaleNumber * purchaseMultiples;
    const pheasantFemaleNumber = this.state.fields.pheasantFemaleNumber * purchaseMultiples;
    const straightrunPheasantNumber = this.state.fields.straightrunNumber * straightrunMultiples;
    let maleCost = 0;
    let femaleCost = 0;
    let straightrunCost = 0;
    let totalMaleCost = 0;
    let totalFemaleCost = 0;
    let totalStraightrunCost = 0;
    let totalCost = 0;

    if (pheasantTypeItemCode !== "") {
      //Get male/female cost and update state.
      const pheasantType = this.getPheasantType(pheasantTypeItemCode, pheasantTypesArray);
      maleCost = pheasantType.cost.male;
      femaleCost = pheasantType.cost.female;
      straightrunCost = this.state.straightrunCost;
    }

    totalMaleCost = pheasantMaleNumber * maleCost;
    totalFemaleCost = pheasantFemaleNumber * femaleCost;
    totalStraightrunCost = straightrunPheasantNumber * straightrunCost;
    totalCost = totalMaleCost + totalFemaleCost + totalStraightrunCost;

    this.setState({
      maleCost: maleCost,
      femaleCost: femaleCost,
      totalMaleCost: totalMaleCost,
      totalFemaleCost: totalFemaleCost,
      totalStraightrunCost: totalStraightrunCost,
      totalAmount: totalCost,
    });
  };

  getPheasantType = (itemCode) => {
    const array = this.state.pheasantTypes;

    for (var i = 0; i < array.length; i++) {
      if (array[i].itemCode === itemCode) {
        return array[i];
      }
    }
  };

  validateForm = () => {
    const validationCheck = validatePheasantForm(this.state);
    let formIsValid = false;

    //* Reset Field Error State
    this.setState({ errors: {} });

    //* Perform Validation
    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors,
        });
      }
    }
    return formIsValid;
  };

  createPaymentSummary = () => {
    const pheasantType = this.state.fields.pheasantType;
    const pheasantTypeName = this.getPheasantType(pheasantType).name;
    const pheasantTypeCode = this.getPheasantType(pheasantType).itemCode;
    const multiple = this.state.purchaseMultiples;
    const straightrunMultiple = this.state.straightrunMultiples;
    const numberOfMales = this.state.fields.pheasantMaleNumber * multiple;
    const numberOfFemales = this.state.fields.pheasantFemaleNumber * multiple;
    const numberOfStraightrun = this.state.fields.straightrunNumber * straightrunMultiple;
    const totalMaleCost = this.state.totalMaleCost;
    const totalFemaleCost = this.state.totalFemaleCost;
    const totalStraightrunCost = this.state.totalStraightrunCost;

    let paymentSummary = [];

    if (numberOfMales > 0) {
      let maleItem = {
        itemName: `(${numberOfMales} ${pheasantTypeName} males)`,
        itemQuantity: 1,
        itemCode: `ph-m-${pheasantTypeCode}`,
        itemTotal: totalMaleCost,
      };

      paymentSummary.push(maleItem);
    }

    if (numberOfFemales > 0) {
      let femaleItem = {
        itemName: `(${numberOfFemales} ${pheasantTypeName} females)`,
        itemQuantity: 1,
        itemCode: `ph-f-${pheasantTypeCode}`,
        itemTotal: totalFemaleCost,
      };

      paymentSummary.push(femaleItem);
    }

    if (numberOfStraightrun > 0) {
      let straightrunItem = {
        itemName: `(${numberOfStraightrun} straightrun )`,
        itemQuantity: 1,
        itemCode: `ph-str`,
        itemTotal: totalStraightrunCost,
      };

      paymentSummary.push(straightrunItem);
    }

    return paymentSummary;
  };

  handleSubmit = (e) => {
    //* Prevent default form action.
    e.preventDefault();
    this.setState({ formSubmitted: true });

    //* Validate the form
    if (this.validateForm()) {
      //* Create new istance of the field object and pass to parent func
      let updateObj = Object.assign({}, this.state.fields);
      //* Create Payment Summary
      updateObj["paymentSummary"] = this.createPaymentSummary();
      updateObj["totalAmount"] = this.state.totalAmount;
      updateObj["redirect"] = "/pheasant";
      this.props.updateFormData(updateObj);
      this.props.setReturnURL("https://www.ab-conservation.com/");
      this.setState({ redirect: true });
    }
  };

  render() {
    const multiple = this.state.purchaseMultiples;
    const straightrunMultiple = this.state.straightrunMultiples;
    const maleCost = this.state.maleCost;
    const femaleCost = this.state.femaleCost;
    const straightrunCost = this.state.straightrunCost;
    const totalMales = multiple * this.state.fields.pheasantMaleNumber;
    const totalFemales = multiple * this.state.fields.pheasantFemaleNumber;
    const totalStraightrun = straightrunMultiple * this.state.fields.straightrunNumber;
    const totalMaleCost = this.state.totalMaleCost;
    const totalFemaleCost = this.state.totalFemaleCost;
    const totalStraightrunCost = this.state.totalStraightrunCost;

    return (
      <div>
        {/* Form redirect */}
        {this.state.redirect && <Redirect to="/payment" push />}

        <form onSubmit={this.handleSubmit}>
          {/* Form Title */}
          <div className="form-group row mb0">
            <div className="col-sm-12">
              <h3 className="mb20">{this.state.fields.formName}</h3>
              <p>
                ACA is offering individuals interested in raising pheasants the opportunity to order
                day-old ring-necked pheasants. The prices below reflect the cost to import these
                pheasants from Wisconsin, and are being collected to make this activity cost neutral
                for ACA. These costs include shipping fees, customs and brokerage fees, and
                veterinary fees, and in the case of day-old male pheasants, the cost of the chicks.
                Through this partnership with ACA, MacFarlane's provides day-old female pheasants
                for free.
              </p>
              <p>
                For more information, please contact Amanda Rezansoff at 780-991-0423 or email&nbsp;
                <a href="mailto:amanda.rezansoff@ab-conservation.com">
                  amanda.rezansoff@ab-conservation.com
                </a>
                .
              </p>
              <p>
                <strong>Please fill out the form below:</strong>
              </p>
            </div>
          </div>
          {/* Pheasant Breed */}
          {this.state.pheasantTypes.length > 1 && (
            <div className="form-group row mb0">
              <label htmlFor="pheasantType" className="col-sm-4 col-form-label">
                <span className="requiredFields">*</span> Pheasant type:
              </label>
              <div className="col-sm-8">
                <select
                  name="pheasantType"
                  className="form-control mb5"
                  value={this.state.fields.pheasantType}
                  onChange={this.onChange}
                >
                  <option value="">-- Choose One --</option>
                  {this.state.pheasantTypes.map((pheasantType) => (
                    <option value={pheasantType.itemCode} key={pheasantType.itemCode}>
                      {pheasantType.name}
                    </option>
                  ))}
                </select>
                {this.state.errors.pheasantType && (
                  <div className="alert-danger alert">
                    <span className="errorMsg">{this.state.errors.pheasantType}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Number of Male Pheasants */}
          <div className="form-group row mb0">
            <div className="col-sm-4">
              <label htmlFor="pheasantMaleNumber" className=" col-form-label">
                Group of {multiple} day-old males <br />({centsToDollarFormat(maleCost * 25)} per
                group):
              </label>
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-2">
                  <input
                    name="pheasantMaleNumber"
                    type="text"
                    className="form-control mb5"
                    value={this.state.fields.pheasantMaleNumber}
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-sm-10">
                  <p className="mt7">
                    <strong>
                      Total: {totalMales} males = {centsToDollarFormat(totalMaleCost)}
                    </strong>{" "}
                    <i>(Max of 500)</i>
                  </p>
                </div>

                <div className="col-sm-12">
                  {this.state.errors.pheasantMaleNumber && (
                    <div className="alert-danger alert">
                      <span className="errorMsg">{this.state.errors.pheasantMaleNumber}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Number of Female Pheasants */}
          <div className="form-group row mb0">
            <label htmlFor="pheasantFemaleNumber" className="col-sm-4 col-form-label">
              Group of {multiple} day-old females
              <br />({centsToDollarFormat(femaleCost * 25)} per group):
            </label>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-2">
                  <input
                    name="pheasantFemaleNumber"
                    type="text"
                    className="form-control mb5"
                    value={this.state.fields.pheasantFemaleNumber}
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-sm-10">
                  <p className="mt7">
                    <strong>
                      Total: {totalFemales} females = {centsToDollarFormat(totalFemaleCost)}
                    </strong>{" "}
                    <i>(Max of 500)</i>
                  </p>
                </div>
                <div className="col-sm-12">
                  {this.state.errors.pheasantFemaleNumber && (
                    <div className="alert-danger alert">
                      <span className="errorMsg">{this.state.errors.pheasantFemaleNumber}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Number of Straightrun Pheasants 
          <div className="form-group row mb0">
            <label
              htmlFor="straightrunNumber"
              className="col-sm-4 col-form-label"
            >
              Group of 100 day-old straightrun
              <br />({centsToDollarFormat(straightrunCost * 100)} per group):
            </label>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-2">
                  <input
                    name="straightrunNumber"
                    type="text"
                    className="form-control mb5"
                    value={this.state.fields.straightrunNumber}
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-sm-10">
                  <p className="mt7">
                    <strong>
                      Total: {totalStraightrun} straightrun ={' '}
                      {centsToDollarFormat(totalStraightrunCost)}
                    </strong>{' '}
                    <i>(Max of 500)</i>
                  </p>
                </div>
                <div className="col-sm-12">
                  {this.state.errors.straightrunNumber && (
                    <div className="alert-danger alert">
                      <span className="errorMsg">
                        {this.state.errors.straightrunNumber}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>*/}

          <div className="row">
            <div className="col-sm-12 mt20">
              <p>
                <i>
                  <strong>
                    MacFarlane Pheasants anticipates a 90% accuracy on the sexing of day-old
                    ring-necked pheasants. This ratio has been factored into the above pricing.
                  </strong>
                </i>
              </p>
            </div>
          </div>
          <hr />
          {/* Display Total Amount */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>Total: {centsToDollarFormat(this.state.totalAmount)}</h2>
              {this.state.errors.totalAmount && (
                <div className="errorMsg">{this.state.errors.totalAmount}</div>
              )}
            </div>
          </div>
          {/* Submit button */}
          <div className="form-group row">
            <div className="col-sm-12">
              {!this.state.processing && (
                <button className="btn btn-primary" disabled={this.state.submitDisabled}>
                  Make Payment
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form.formData,
  };
};

export default connect(
  mapStateToProps,
  { updateFormData, setReturnURL }
)(Template);
