const validateDogCalendarForm = state => {
  let response = { formIsValid: true, errors: {} };

  if (state.totalAmount === 0) {
    response.formIsValid = false;
    response.errors['totalAmount'] = '* You must purchase at least one item.';
  }

  return response;
};

export default validateDogCalendarForm;
