const validateDonationForm = state => {
  let fields = state.fields;
  let response = { formIsValid: true, errors: {} };

  if (!fields['donationAmount']) {
    response.formIsValid = false;
    response.errors['donationAmount'] = '* Enter a donation amount.';
  }

  if (Number(fields['donationAmount']) < 20) {
    response.formIsValid = false;
    response.errors['donationAmount'] = '* Enter an amount $20 or greater.';
  }

  if (fields['donationAmount'] > 5000) {
    response.formIsValid = false;
    response.errors['donationAmount'] = '* Enter an amount less than $5000.';
  }

  if (!fields['donationType']) {
    response.formIsValid = false;
    response.errors['donationType'] = '* Please choose a donation type.';
  }

  if (fields['isMemorialDonation']) {
    if (!fields['inMemoryOf']) {
      response.formIsValid = false;
      response.errors['inMemoryOf'] = '* Please enter a full name.';
    }
  }

  return response;
};

export default validateDonationForm;
