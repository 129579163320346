export const updateFormData = () => {
  return {
    type: 'UPDATE_FORMDATA',
  };
};

export const setReturnURL = () => {
  return {
    type: 'SET_RETURN_URL',
  };
};

export const setReturnButtonText = () => {
  return {
    type: 'SET_RETURN_BUTTON_TEXT',
  };
};

export const clearFormData = () => {
  return {
    type: 'CLEAR_FORMDATA',
  };
};

export const setErrorMsg = () => {
  return {
    type: 'SET_ERROR_MSG',
  };
};
