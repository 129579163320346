import React from 'react';

const SelectOption = (props) => {
  // Create and array of numbers for map.
  let countArray = [];
  let count = props.count + 1;

  if (count > 10) {
    count = 11;
  }

  for (let index = 0; index < count; index++) {
    countArray.push(index);
  }

  return (
    <>
      {countArray.map((x) => (
        <option key={x} value={x}>
          {x}
        </option>
      ))}
    </>
  );
};

export default SelectOption;
