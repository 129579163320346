import { pheasantFormDefaults } from '../settings/pheasantFormDefaults';

const Validator = require('validator');

const validatePheasantForm = (state) => {
  let fields = state.fields;
  let response = { formIsValid: true, errors: {} };

  if (!fields['pheasantType']) {
    response.formIsValid = false;
    response.errors['pheasantType'] = '* Choose a pheasant type.';
  }

  if (
    fields['pheasantMaleNumber'] === '0' &&
    fields['pheasantFemaleNumber'] === '0' &&
    fields['straightrunNumber'] === '0'
  ) {
    response.formIsValid = false;
    response.errors['straightrunNumber'] =
      '* Please choose a number of birds to purchase.';
  }

  if (
    !Validator.isInt(fields['pheasantMaleNumber'], {
      max: pheasantFormDefaults.formDefaults.maxPurchase
    })
  ) {
    response.formIsValid = false;
    response.errors[
      'pheasantMaleNumber'
    ] = `* Please enter a value of ${pheasantFormDefaults.formDefaults.maxPurchase} or less.`;
  }

  if (!fields['pheasantMaleNumber']) {
    response.formIsValid = false;
    response.errors[
      'pheasantMaleNumber'
    ] = `* Please enter a value of ${pheasantFormDefaults.formDefaults.maxPurchase} or less.`;
  }

  if (
    !Validator.isInt(fields['pheasantFemaleNumber'], {
      max: pheasantFormDefaults.formDefaults.maxPurchase
    })
  ) {
    response.formIsValid = false;
    response.errors[
      'pheasantFemaleNumber'
    ] = `* Please enter a value of ${pheasantFormDefaults.formDefaults.maxPurchase} or less.`;
  }

  if (!fields['pheasantFemaleNumber']) {
    response.formIsValid = false;
    response.errors[
      'pheasantFemaleNumber'
    ] = `* Please enter a value of ${pheasantFormDefaults.formDefaults.maxPurchase} or less.`;
  }

  if (
    !Validator.isInt(fields['straightrunNumber'], {
      max: pheasantFormDefaults.formDefaults.maxPurchase
    })
  ) {
    response.formIsValid = false;
    response.errors[
      'straightrunNumber'
    ] = `* Please enter a value of ${pheasantFormDefaults.formDefaults.maxPurchase} or less.`;
  }

  if (!fields['straightrunNumber']) {
    response.formIsValid = false;
    response.errors[
      'straightrunNumber'
    ] = `* Please enter a value of ${pheasantFormDefaults.formDefaults.maxPurchase} or less.`;
  }

  return response;
};

export default validatePheasantForm;
