import React, { Component } from 'react';
import validateTemplateForm from '../../validation/validateDogCalendarForm';
import isEmpty from '../../util/isEmpty';
import centsToDollarFormat from '../../util/centsToDollarFormat';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { updateFormData, setReturnURL } from '../../actions/formActions';
import SelectOption from './SelectOptionMP';
import axios from 'axios';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class DogCalendarForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        formName: '',
        itemCount: 1,

        isAdult: false,
        item1Name: '10th Anniversary TPF Dog Calendar',
        item1Code: 'TPFCAL',
        item1Quantity: '',
        item1Price: 1000, // In cents
        showItem1Field: true,
        item1Remaining: 0,
      },
      totalAmount: 0,
      errors: {},
      stripeTotalAmount: 1,
      stripeMetaData: {
        name: '',
      },
      stripeDescription: '',
      redirect: false,
    };
  }

  componentDidMount() {
    axios
      .get(REACT_APP_API_URL + 'inventory/' + this.state.fields.item1Code)
      //* Evaluate received response
      .then((res) => {
        console.log(res.data.itemsRemaining);
        this.setState({
          showItem1Field: res.data.available,
          item1Remaining: res.data.itemsRemaining,
        });
      });

    //* Update state if values exsist.
    if (this.props.form) {
      const formData = this.props.form;
      const formFieldState = this.state.fields;

      if (
        typeof formData.formName !== 'undefined' &&
        formData.formName === formFieldState.formName
      ) {
        //* Create a complete object from current state
        let newObj = {};
        Object.keys(formFieldState).forEach((item) => {
          newObj[item] = formFieldState[item];
        });

        //* Update key data if within the prop object
        Object.keys(formData).forEach((item) => {
          if (newObj.hasOwnProperty(item)) {
            newObj[item] = formData[item];
          }
        });

        //* Update the state from the updated object
        this.setState(
          (prevState, props) => ({
            fields: newObj,
          }),
          () => {
            this.updateTotalAmount();
          }
        );
      } //* TODO: Add Redirect to error page
    }
  }

  createSelectItems(count) {
    let items = [];
    for (let i = 0; i <= this.props.count; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }

  //* Form functions
  onChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          [name]: value,
        },
      }),
      () => {
        this.updateTotalAmount();
        this.updateABVerify();
      }
    );
  };

  updateTotalAmount = () => {
    const item1Total =
      this.state.fields.item1Price * this.state.fields.item1Quantity;
    const totalAmount = item1Total;

    this.setState({ totalAmount: totalAmount });
  };

  //* Checks to see if we need to verify if the Purchaser is an Alberta Resident.
  updateABVerify = () => {
    let abVerify = false;

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        abVerify: abVerify,
      },
    }));
  };

  validateForm = () => {
    const validationCheck = validateTemplateForm(this.state);
    let formIsValid = false;

    //* Reset Field Error State
    this.setState({ errors: {} });

    //* Perform Validation
    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors,
        });
      }
    }
    return formIsValid;
  };

  createPaymentSummary = () => {
    let paymentSummary = [];

    if (this.state.fields.item1Quantity > 0) {
      const Item1Summary = {
        itemName: this.state.fields.item1Name,
        itemQuantity: this.state.fields.item1Quantity,
        itemTotal:
          this.state.fields.item1Price * this.state.fields.item1Quantity,
        itemCode: this.state.fields.item1Code,
      };

      paymentSummary.push(Item1Summary);
    }

    return paymentSummary;
  };

  handleSubmit = (e) => {
    //* Prevent default form action.
    e.preventDefault();

    console.log(this.validateForm());
    //* Validate the form
    if (this.validateForm()) {
      //* Create new istance of the field object and pass to parent func
      let updateObj = Object.assign({}, this.state.fields);

      //* Create Payment Summary
      updateObj['paymentSummary'] = this.createPaymentSummary();
      updateObj['totalAmount'] = this.state.totalAmount;
      updateObj['redirect'] = '/anniversarymerchandise';

      this.props.updateFormData(updateObj);
      this.props.setReturnURL(
        'https://www.ab-conservation.com/events/taber-pheasant-festival/'
      );

      this.setState({ redirect: true });
    }
  };

  render() {
    return (
      <div>
        {/* Form redirect */}
        {this.state.redirect && <Redirect to="/payment" push />}

        <form onSubmit={this.handleSubmit}>
          {/* Form Title */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>{this.state.fields.formName}</h2>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-6">
              <h1 className="TPFMainHeaderClass">Taber Pheasant Festival</h1>
              <h2 className="TPFMainSubHeaderClass">
                10th Anniversary Dog Calendar
              </h2>
              <p>
                We can't think of a better way to mark ten years running than with the festival's four-legged friends! Whether you have your own companion or admire other hunting dogs doing what they do best, you can appreciate the love and loyalty they bring to the Taber Pheasant Festival. Celebrate with us—get your own high-quality calendar today.
              </p>
            </div>
            <div className="col-sm-6">
              <img
                alt=""
                className="mt30 img-responsive"
                src="https://www.ab-conservation.com/default/includes/themes/ACA/assets/images/taber_pheasant_festival/10th_Anniversary_Dog_Calendar.jpg"
              />
            </div>
          </div>

          <hr />

          {/* item1Quantity Select Box */}
          {this.state.showItem1Field && (
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-2 col-form-label">
                <strong>Choose quantity: </strong>{/*(
                this.state.item1Remaining < 101 && (
                  <>
                    $50 each. Limit of 4 per customer.{' '}
                    {this.state.item1Remaining} remaining.)
                    <br />
                  </>
                )*/}
              </label>
              <div className="col-sm-2">
                <select
                  name="item1Quantity"
                  className="form-control"
                  value={this.state.fields.item1Quantity}
                  onChange={this.onChange}
                >
                  <SelectOption count={this.state.item1Remaining} />
                </select>
                {this.state.errors.item1Quantity && (
                  <div className="errorMsg">
                    {this.state.errors.item1Quantity}
                  </div>
                )}
              </div>
            </div>
          )}
          {!this.state.showItem1Field && (
            <div className="grey">
              {this.state.fields.item1Name}. <strong>SOLD OUT!</strong>
            </div>
          )}

          <hr />

          {/* Display Total Amount */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>Total: {centsToDollarFormat(this.state.totalAmount)}</h2>
              {this.state.errors.totalAmount && (
                <div className="errorMsg">{this.state.errors.totalAmount}</div>
              )}
            </div>
          </div>

          {/* Submit button */}
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn-primary"
                disabled={this.state.submitDisabled}
              >
                Make Payment
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form.formData,
  };
};

export default connect(
  mapStateToProps,
  { updateFormData, setReturnURL }
)(DogCalendarForm);
