export const pheasantFormDefaults = {
  pheasantTypes: [
    {
      name: "day-old ring-necked pheasant",
      itemCode: "rnp",
      cost: { male: 435, female: 110 },
    },
  ],
  formDefaults: {
    minPurchase: 1,
    maxPurchase: 20,
    purchaseMultiples: 25,
  },
};

// For multiple species in list follow example below:
// {
//   name: 'Ring-necked pheasant',
//   itemCode: 'rnp',
//   cost: { male: 300, female: 75 }
// },
// {
//   name: 'Melanistic Mutants',
//   itemCode: 'mmp',
//   cost: { male: 450, female: 300 }
// }
