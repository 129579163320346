import React, { Component } from 'react';
import { connect } from 'react-redux';

class Success extends Component {
  onComponentDidMount() {}

  redirect = () => {
    window.location.href = this.props.form.returnURL;
  };

  render() {
    return (
      <div>
        <h1>Success</h1>
        <div className="alert alert-danger" role="alert">
          {this.props.form.errorMsg}
        </div>

        <button
          type="button"
          className="btn btn-primary"
          onClick={this.redirect}
        >
          {this.props.form.returnButtonText}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    form: state.form,
  };
};

export default connect(mapStateToProps)(Success);
