import validator from "validator";

const validatePaymentForm = (state) => {
  let fields = state.fields;
  let response = { formIsValid: true, errors: {} };

  if (!fields["description"]) {
    response.formIsValid = false;
    response.errors["description"] = "* Please choose an option.";
  }

  if (!fields["name"]) {
    response.formIsValid = false;
    response.errors["name"] = "* Please enter your name.";
  }

  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(fields["email"])) {
    response.formIsValid = false;
    response.errors["email"] = "* Please enter a valid email.";
  }

  if (fields["email_verification"] !== fields["email"]) {
    response.formIsValid = false;
    response.errors["email_verification"] = "* Email verification does not match.";
  }

  if (!fields["address_line1"]) {
    response.formIsValid = false;
    response.errors["address_line1"] = "* Please enter an address.";
  }

  if (!validator.isMobilePhone(fields["phone"])) {
    response.formIsValid = false;
    response.errors["phone"] = "* Please enter a valid phone number.";
  }

  if (!fields["address_city"]) {
    response.formIsValid = false;
    response.errors["address_city"] = "* Please enter a city.";
  }

  if (!fields["address_state"]) {
    response.formIsValid = false;
    response.errors["address_state"] = "* Please enter an province.";
  }

  if (state.abVerify && !fields.showShippingInfo && fields["address_state"]) {
    var abVerifyLowercase = fields["address_state"].toLowerCase();

    if (abVerifyLowercase.match(/^(alberta|ab|alta)$/)) {
    } else {
      response.formIsValid = false;
      response.errors["address_state"] =
        "* You must be an Alberta resident to purchace a raffle ticket.";
    }
  }

  if (!fields["address_zip"]) {
    response.formIsValid = false;
    response.errors["address_zip"] = "* Please enter a postal code.";
  }

  if (!fields["address_country"]) {
    response.formIsValid = false;
    response.errors["address_country"] = "* Please enter a country.";
  }

  if (!fields.captchaVerified) {
    response.formIsValid = false;
    response.errors["captcha"] = "* Please complete the reCAPTCHA.";
  }

  if (!fields.cardFieldComplete && !state.discountAvailable) {
    response.formIsValid = false;
    response.errors["cardElement"] = "* Please check credit card information.";
  }

  if (state.abVerify && !fields.confirmIndividual) {
    console.log("hello!!!!");
    response.formIsValid = false;
    response.errors["confirmIndividual"] =
      "* Please confirm you are purchasing raffle tickets for an individual.";
  }

  if (fields.showShippingInfo) {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(fields["email_ship"])) {
      response.formIsValid = false;
      response.errors["email_ship"] = "* Please enter a valid shipping email.";
    }
    if (!validator.isMobilePhone(fields["phone_ship"])) {
      response.formIsValid = false;
      response.errors["phone_ship"] = "* Please enter a valid shipping phone number.";
    }
    if (!fields.name_ship) {
      response.formIsValid = false;
      response.errors["name_ship"] = "* Please enter a shipping name.";
    }
    if (!fields.address_line1_ship) {
      response.formIsValid = false;
      response.errors["address_line1_ship"] = "* Please enter a shipping address.";
    }
    if (!fields["address_city_ship"]) {
      response.formIsValid = false;
      response.errors["address_city_ship"] = "* Please enter a shipping city.";
    }

    if (!fields["address_state_ship"]) {
      response.formIsValid = false;
      response.errors["address_state_ship"] = "* Please enter an shipping province.";
    }

    if (state.abVerify && fields["address_state_ship"]) {
      console.log("checking!");
      var abVerifyLowercaseShip = fields["address_state_ship"].toLowerCase();
      if (abVerifyLowercaseShip.match(/^(alberta|ab|alta)$/)) {
      } else {
        response.formIsValid = false;
        response.errors["address_state_ship"] =
          "* You must be an Alberta resident to purchace a raffle ticket.";
      }
    }

    if (!fields["address_zip_ship"]) {
      response.formIsValid = false;
      response.errors["address_zip_ship"] = "* Please enter a shipping postal code.";
    }

    if (!fields["address_country_ship"]) {
      response.formIsValid = false;
      response.errors["address_country_ship"] = "* Please enter a shipping country.";
    }
  }

  return response;
};

export default validatePaymentForm;
