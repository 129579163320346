import React from 'react';

const DefaultLayout = ({ children }) => (
  <div className="landing">
    <div className="dark-overlay landing-inner text-dark">
      <div className="container trans">
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <div className="card mt-4">
              <div className="card-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DefaultLayout;
