const validatePaymentForm = state => {
  let fields = state.fields;
  let response = { formIsValid: true, errors: {} };

  if (!fields['name']) {
    response.formIsValid = false;
    response.errors['name'] = '* Please provide a name.';
  }

  if (!fields['isAdult']) {
    response.formIsValid = false;
    response.errors['isAdult'] = '* You must verify you are an adult.';
  }

  if (state.totalAmount === 0) {
    response.formIsValid = false;
    response.errors['totalAmount'] = '* You must purchase at least one item.';
  }

  return response;
};

export default validatePaymentForm;
