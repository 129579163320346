import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

class Checkout extends Component {
  render() {
    return (
      <div>
        <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
          <Elements>
            <InjectedCheckoutForm
              amount={this.props.match.params.amount}
              item={this.props.match.params.item}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

export default Checkout;
