import React, { Component } from 'react';
import validateNWSGCForm from '../../validation/validateNWSGCForm';
import isEmpty from '../../util/isEmpty';
import centsToDollarFormat from '../../util/centsToDollarFormat';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { updateFormData } from '../../actions/formActions';
import NumberFormat from 'react-number-format';
import { NWSGCRegistrationSettings } from '../../settings/NWSGCRegistrationSettings';
import Popup from 'reactjs-popup';
import '../../css/modal.css';

class DonationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        formName: 'NWSGC Registration Form',
        registrationType: '2020NWSGC',
        discountCode: '',
      },
      priceMode: 'early',
      registrationTypes: [],
      errors: {},
      totalAmount: 2500,
      redirect: false,
    };
  }

  componentDidMount() {
    //Set form default data
    console.log('Component did mount Fired!');
    // this.setState(
    //   {
    //     registrationTypes: NWSGCRegistrationSettings.registrationTypes,
    //   },
    //   () => {
    //     //* Update state if values exsist.
    //     if (this.props.form) {
    //       const formData = this.props.form;
    //       const formFieldState = this.state.fields;

    //       if (
    //         typeof formData.formName !== 'undefined' &&
    //         formData.formName === formFieldState.formName
    //       ) {
    //         //* Create a complete object from current state
    //         let newObj = {};
    //         Object.keys(formFieldState).forEach((item) => {
    //           newObj[item] = formFieldState[item];
    //         });

    //         //* Update key data if within the prop object
    //         Object.keys(formData).forEach((item) => {
    //           if (newObj.hasOwnProperty(item)) {
    //             newObj[item] = formData[item];
    //           }
    //         });

    //         //* Update the state from the updated object
    //         this.setState((prevState, props) => ({
    //           fields: newObj,
    //         }));
    //       }
    //     }
    //     setTimeout(() => {
    //       this.updateTotalAmount();
    //     }, 200);
    //   }
    // );
  }

  //* Form functions
  onChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          [name]: value,
        },
      }),
      () => {
        this.updateTotalAmount();
      }
    );
  };

  updateTotalAmount = () => {
    // Get the current registration price
    let currentRegistrationPrice = 0;

    const currentRegistrationObject = this.state.registrationTypes.find(
      ({ registrationTypeCode }) =>
        registrationTypeCode === this.state.fields.registrationType
    );

    if (typeof currentRegistrationObject !== 'undefined') {
      currentRegistrationPrice =
        this.state.priceMode === 'early'
          ? currentRegistrationObject.earlyRegistrationPrice
          : currentRegistrationObject.regularRegistrationPrice;
    }

    const totalAmount = currentRegistrationPrice;

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
      },
      totalAmount: 2500,
    }));
  };

  validateForm = () => {
    const validationCheck = validateNWSGCForm(this.state);
    let formIsValid = false;

    //* Reset Field Error State
    this.setState({ errors: {} });

    //* Perform Validation
    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors,
        });
      }
    }
    return formIsValid;
  };

  createPaymentSummary = () => {
    let text1 = '';
    let paymentSummary = [];

    // Add registration summary.
    if (this.state.fields.registrationType !== 'none') {
      let currentRegistrationPrice = 2500;

      // const currentRegistrationObject = this.state.registrationTypes.find(
      //   ({ registrationTypeCode }) =>
      //     registrationTypeCode === this.state.fields.registrationType
      // );

      // if (this.state.priceMode === 'early') {
      //   currentRegistrationPrice =
      //     currentRegistrationObject.earlyRegistrationPrice;
      // } else {
      //   currentRegistrationPrice =
      //     currentRegistrationObject.regularRegistrationPrice;
      // }

      // text1 = `${currentRegistrationObject.registrationType} NWSGC Registration`;

      // Add the date if day pass.
      // if (currentRegistrationObject.registrationTypeCode === 'godo') {
      //   text1 = `${text1} for ${this.state.fields.oneDayPassDate}`;
      // }

      paymentSummary.push({
        itemName: 'NWSGC 2020 Online Registration',
        itemQuantity: 1,
        itemTotal: currentRegistrationPrice,
        itemCode: 'NWSGC2020',
      });
    }

    return paymentSummary;
  };

  handleSubmit = (e) => {
    //* Prevent default form action.
    e.preventDefault();

    //* Validate the form
    if (this.validateForm()) {
      console.log('done');
      //* Create new istance of the field object and pass to parent func
      let updateObj = Object.assign({}, this.state.fields);

      //* Create Payment Summary
      updateObj['registrationType'] = 'NWSGC2020';
      updateObj['paymentSummary'] = this.createPaymentSummary();
      updateObj['totalAmount'] = this.state.totalAmount;
      updateObj['redirect'] = '/NWSGC';

      this.props.updateFormData(updateObj);

      this.setState({ redirect: true });
    }
  };

  render() {
    const test = {
      width: '400px',
    };
    return (
      <div>
        {/* Form redirect */}
        {this.state.redirect && <Redirect to="/payment" push />}

        <form onSubmit={this.handleSubmit}>
          {/* Form Title */}
          <div className="form-group row">
            <div className="col-sm-2">
              <img
                alt=""
                className="mb10 img-responsive"
                src="https://www.ab-conservation.com/default/includes/themes/ACA/assets/images/logos/nwsgc-logo.png"
              />
            </div>
            <div className="col-sm-8">
              <img
                alt=""
                className="mb10 img-responsive"
                src="https://www.ab-conservation.com/default/includes/themes/ACA/assets/images/static/nwsgc-title.png"
              />
            </div>
            <div className="col-sm-12">
              <h2>Online Registration Form</h2>
              <div>
                <i>All costs are in Canadian currency and include tax.</i>
              </div>
            </div>
          </div>

          <hr />

          {/* ProjectType Field*/}
          <div className="form-group row">
            <label htmlFor="donationType" className="col-sm-4 col-form-label">
              Registration Type:{' '}
            </label>
            <div className="col-sm-8">
              Online Conference ($25)
              {/* <select
                name="registrationType"
                className="form-control"
                value={this.state.fields.registrationType}
                onChange={this.onChange}
              >
                <option value="none">--Please choose an option--</option>
                {this.state.registrationTypes.map((registrationType) => (
                  <option
                    value={registrationType.registrationTypeCode}
                    key={registrationType.registrationTypeCode}
                  >
                    {registrationType.registrationType} (
                    {centsToDollarFormat(
                      registrationType.earlyRegistrationPrice
                    )}
                    )
                  </option>
                ))}
              </select> */}
              {this.state.errors.registrationType && (
                <div className="errorMsg">
                  {this.state.errors.registrationType}
                </div>
              )}
            </div>
          </div>

          {/* Discount Code */}
          <div className="form-group row">
            <label htmlFor="email" className="col-sm-4 col-form-label">
              Discount Code (case sensitive):
              <br />
              <strong>This only applies to sponsors.</strong>
            </label>
            <div className="col-sm-8">
              <input
                name="discountCode"
                type="text"
                className="form-control"
                placeholder="Please enter a discount code"
                value={this.state.fields.discountCode}
                onChange={this.onChange}
              />
              {this.state.errors.discountCode && (
                <div className="errorMsg">{this.state.errors.discountCode}</div>
              )}
            </div>
          </div>

          <hr />

          {/* Display Total Amount */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>
                Total:{' '}
                <NumberFormat
                  displayType={'text'}
                  name="donationAmount"
                  decimalScale={0}
                  fixedDecimalScale={true}
                  prefix={'$'}
                  value={centsToDollarFormat(this.state.totalAmount)}
                />
              </h2>
              {this.state.errors.totalAmount && (
                <div className="errorMsg">{this.state.errors.totalAmount}</div>
              )}
            </div>
          </div>

          {/* Submit button */}
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn-primary"
                disabled={this.state.submitDisabled}
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form.formData,
  };
};

export default connect(
  mapStateToProps,
  { updateFormData }
)(DonationForm);
