import { UPDATE_FORMDATA } from '../actions/types';
import { SET_RETURN_URL } from '../actions/types';
import { SET_RETURN_BUTTON_TEXT } from '../actions/types';
import { CLEAR_FORMDATA } from '../actions/types';
import { SET_ERROR_MSG } from '../actions/types';

const initialState = {
  formData: {},
  errorMsg: 'Oops something went wrong! Please contact the webmaster. (Err E1)',
  returnURL: '',
  returnButtonText: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FORMDATA:
      return {
        ...initialState,
        formData: action.payload,
      };
    case SET_RETURN_URL:
      return Object.assign({}, state, {
        returnURL: action.payload,
      });
    case SET_RETURN_BUTTON_TEXT:
      return Object.assign({}, state, {
        returnButtonText: action.payload,
      });
    case SET_ERROR_MSG:
      return Object.assign({}, state, {
        errorMsg: action.payload,
      });
    case CLEAR_FORMDATA:
      return Object.assign({}, state, {
        formData: {},
      });
    default:
      return state;
  }
}
