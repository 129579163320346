import React, { Component } from "react";
import validateTemplateForm from "../../validation/validateTPFGeneralForm";
import centsToDollarFormat from "../../util/centsToDollarFormat";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { updateFormData, setReturnURL } from "../../actions/formActions";
import SelectOption from "./SelectOption";
import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class Template extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      fields: {
        formName: "Taber Pheasant Festival Purchase Form",
        itemCount: 1,
        abVerify: false,
      },
      totalAmount: 0,
      errors: {},
      stripeTotalAmount: 7,
      stripeMetaData: { name: "" },
      stripeDescription: "",
      redirect: false,
    };

    // Initialize item fields
    const items = [
      {
        name: "Raf A: Ber A4 X P",
        title: "Raffle A - Beretta A400 Xtreme Plus",
        code: "TPF2024RA",
        price: 2000,
      },
      {
        name: "Raf B: Ben E",
        title: "Raffle B - Benelli ETHOS",
        code: "TPF2024RB",
        price: 2000,
      },
      {
        name: "Raf C: Sako 90 A",
        title: "Raffle C - Sako 90 Adventure",
        code: "TPF2024RC",
        price: 2000,
      },
      {
        name: "Raf D: Ber 686 SP 20G",
        title: "Raffle D - Beretta 686 Silver Pigeon 20G",
        code: "TPF2024RD",
        price: 2000,
      },
      {
        name: "Raf E: Sako S90 P",
        title: "Raffle E - Sako S90 Peak SS",
        code: "TPF2024RE",
        price: 2000,
      },
      {
        name: "Raf F: Ber 686 SP 12G",
        title: "Raffle F - Beretta 686 Silver Pigeon 12G",
        code: "TPF2024RF",
        price: 2000,
      },
      { name: "Raf G: E-Bike", title: "Raffle G - E-Bike", code: "TPF2024RG", price: 2000 },
      {
        name: "Raf H: 3 Hunt Sites 2025",
        title: "Raffle H - 3 Hunting Sites 2025 TPF",
        code: "TPF2024RH",
        price: 1000,
      },
      {
        name: "Banquet",
        title: "Celebration Banquet & Silent Auction",
        code: "TPF2024BT",
        price: 7500,
      },
      {
        name: "Culinary",
        title: "Game to Gourmet Culinary Tasting",
        code: "TPF2024CT",
        price: 3000,
      },
      {
        name: "HYO: Tips & Tasters",
        title: "Harvest Your Own: Tips and Tasters",
        code: "TPF2024HTT",
        price: 3500,
      },
    ];

    items.forEach((item, index) => {
      initialState.fields[`item${index + 1}Name`] = item.name;
      initialState.fields[`item${index + 1}Title`] = item.title;
      initialState.fields[`item${index + 1}Code`] = item.code;
      initialState.fields[`item${index + 1}Quantity`] = "";
      initialState.fields[`item${index + 1}Price`] = item.price;
      initialState.fields[`showItem${index + 1}Field`] = false;
      initialState.fields[`item${index + 1}Remaining`] = 0;
      initialState.fields[`item${index + 1}Active`] = false;
    });

    this.state = initialState;
  }

  componentDidMount() {
    const fetchInventory = (itemCode, index) => {
      return axios.get(`${REACT_APP_API_URL}inventory/${itemCode}`).then((res) => ({
        isActive: res.data.isActive,
        available: res.data.available,
        itemsRemaining: res.data.itemsRemaining,
        index,
      }));
    };

    const itemCodes = Array.from({ length: 11 }, (_, i) => this.state.fields[`item${i + 1}Code`]);
    const inventoryPromises = itemCodes.map((code, index) => fetchInventory(code, index));

    Promise.all(inventoryPromises).then((results) => {
      const newFields = { ...this.state.fields };
      results.forEach(({ isActive, available, itemsRemaining, index }) => {
        newFields[`item${index + 1}Active`] = isActive;
        newFields[`showItem${index + 1}Field`] = available;
        newFields[`item${index + 1}Remaining`] = itemsRemaining;
      });
      this.setState({ fields: newFields });
    });

    if (this.props.form) {
      const formData = this.props.form;
      if (formData.formName === this.state.fields.formName) {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            ...formData,
          },
        }));
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const relevantFields = Array.from({ length: 11 }, (_, i) => `item${i + 1}Quantity`);
    const quantityChanged = relevantFields.some(
      (field) => prevState.fields[field] !== this.state.fields[field]
    );

    if (quantityChanged) {
      this.updateTotalAmount();
      this.updateABVerify();
    }
  }

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };

  updateTotalAmount = () => {
    const totalAmount = Array.from(
      { length: 11 },
      (_, i) =>
        this.state.fields[`item${i + 1}Price`] * (this.state.fields[`item${i + 1}Quantity`] || 0)
    ).reduce((acc, curr) => acc + curr, 0);

    this.setState({ totalAmount });
  };

  updateABVerify = () => {
    const raffleTicketQuantity = Array.from(
      { length: 8 },
      (_, i) => this.state.fields[`item${i + 1}Quantity`] || 0
    ).reduce((acc, curr) => acc + curr, 0);

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        abVerify: raffleTicketQuantity > 0,
      },
    }));
  };

  validateForm = () => {
    const validationCheck = validateTemplateForm(this.state);
    this.setState({ errors: validationCheck.errors });
    return validationCheck.formIsValid;
  };

  createPaymentSummary = () => {
    return Array.from({ length: 11 }, (_, i) => ({
      itemName: this.state.fields[`item${i + 1}Name`],
      itemQuantity: this.state.fields[`item${i + 1}Quantity`] || 0,
      itemTotal:
        this.state.fields[`item${i + 1}Price`] * (this.state.fields[`item${i + 1}Quantity`] || 0),
      itemCode: this.state.fields[`item${i + 1}Code`],
    })).filter((item) => item.itemQuantity > 0);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      const updateObj = {
        ...this.state.fields,
        paymentSummary: this.createPaymentSummary(),
        totalAmount: this.state.totalAmount,
        redirect: "/tpfgeneral",
      };

      this.props.updateFormData(updateObj);
      this.props.setReturnURL("https://www.ab-conservation.com/events/taber-pheasant-festival/");
      this.setState({ redirect: true });
    }
  };

  render() {
    return (
      <div>
        {this.state.redirect && <Redirect to="/payment" push />}
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>{this.state.fields.formName}</h2>
            </div>
          </div>
          <hr />
          {Array.from({ length: 11 }, (_, i) => (
            <div key={i}>
              {this.state.fields[`item${i + 1}Active`] && (
                <div>
                  {this.state.fields[`showItem${i + 1}Field`] ? (
                    <>
                      <div className="form-group row">
                        <label htmlFor="name" className="col-sm-10 col-form-label">
                          <strong>{this.state.fields[`item${i + 1}Title`]}</strong> (
                          {centsToDollarFormat(this.state.fields[`item${i + 1}Price`])}){" "}
                          {this.state.fields[`item${i + 1}Remaining`] < 100 && (
                            <>{this.state.fields[`item${i + 1}Remaining`]} remaining.</>
                          )}
                        </label>
                        <div className="col-sm-2">
                          <select
                            name={`item${i + 1}Quantity`}
                            className="form-control"
                            value={this.state.fields[`item${i + 1}Quantity`]}
                            onChange={this.onChange}
                          >
                            <SelectOption count={this.state.fields[`item${i + 1}Remaining`]} />
                          </select>
                          {this.state.errors[`item${i + 1}Quantity`] && (
                            <div className="errorMsg">
                              {this.state.errors[`item${i + 1}Quantity`]}
                            </div>
                          )}
                        </div>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <>
                      <div className="grey">
                        {this.state.fields[`item${i + 1}Name`]}. <strong>SOLD OUT!</strong>
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>Total: {centsToDollarFormat(this.state.totalAmount)}</h2>
              {this.state.errors.totalAmount && (
                <div className="errorMsg">{this.state.errors.totalAmount}</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <button className="btn btn-primary" disabled={this.state.submitDisabled}>
                Make Payment
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form.formData,
});

export default connect(
  mapStateToProps,
  { updateFormData, setReturnURL }
)(Template);
