import React, { Component } from 'react';
import validateDonationForm from '../../validation/validateDonationForm';
import isEmpty from '../../util/isEmpty';
import centsToDollarFormat from '../../util/centsToDollarFormat';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { updateFormData } from '../../actions/formActions';
import NumberFormat from 'react-number-format';
import { donationTypes, getDonationTypeName } from '../../settings/donationTypes';

class DonationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        donationType: '',
        donationAmount: '',
        formName: 'Donation',
        isMemorialDonation: false,
        inMemoryOf: ''
      },
      errors: {},
      totalAmount: 0,
      redirect: false
    };
  }

  componentDidMount() {
    //* Update state if values exsist in match params

    let incomingType = '';
    let incomingAmount = '';

    if (typeof this.props.match.params.type !== 'undefined') {
      incomingType = this.props.match.params.type;
    }

    if (typeof this.props.match.params.amount !== 'undefined') {
      incomingAmount = this.props.match.params.amount;
    }

    this.setState({
      fields: {
        ...this.state.fields,
        donationAmount: incomingAmount,
        donationType: incomingType
      }
    });

    //* Update type and amount if exsists in route match

    if (this.props.form) {
      const formData = this.props.form;
      const formFieldState = this.state.fields;

      if (typeof formData.formName !== 'undefined' && formData.formName === formFieldState.formName) {
        //* Create a complete object from current state
        let newObj = {};
        Object.keys(formFieldState).forEach(item => {
          newObj[item] = formFieldState[item];
        });

        //* Update key data if within the prop object
        Object.keys(formData).forEach(item => {
          if (newObj.hasOwnProperty(item)) {
            newObj[item] = formData[item];
          }
        });

        //* Update the state from the updated object
        this.setState(
          (prevState, props) => ({
            fields: newObj
          }),
          () => {
            this.updateTotalAmount();
          }
        );
      }
      //* TODO: Add Redirect to error page
    }
  }

  //* Form functions
  onChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      prevState => ({
        fields: {
          ...prevState.fields,
          [name]: value
        }
      }),
      () => {
        this.updateTotalAmount();
      }
    );
  };

  onUpdateDonationAmount = value => {
    const donationAmount = value;
    this.setState(
      prevState => ({
        fields: {
          ...prevState.fields,
          donationAmount: donationAmount
        }
      }),
      () => {
        this.updateTotalAmount();
      }
    );
  };

  updateTotalAmount = () => {
    const totalAmount = this.state.fields.donationAmount * 100;
    this.setState({ totalAmount: totalAmount });
  };

  validateForm = () => {
    const validationCheck = validateDonationForm(this.state);
    let formIsValid = false;

    //* Reset Field Error State
    this.setState({ errors: {} });

    //* Perform Validation
    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors
        });
      }
    }
    return formIsValid;
  };

  createPaymentSummary = () => {
    let text = '';

    if (!this.state.fields.isMemorialDonation) {
      text = `ACA ${getDonationTypeName(this.state.fields.donationType)} donation of ${centsToDollarFormat(this.state.totalAmount)}.`;
    } else {
      text = `ACA ${getDonationTypeName(this.state.fields.donationType)} donation of ${centsToDollarFormat(this.state.totalAmount)} in memory of ${this.state.fields.inMemoryOf}.`;
    }

    const paymentSummary = [
      {
        itemName: text,
        itemQuantity: 1,
        itemTotal: this.state.totalAmount,
        itemCode: this.state.fields.donationType
      }
    ];
    console.log(getDonationTypeName(this.state.fields.donationType));
    return paymentSummary;
  };

  createOption = type => {
    return (
      <option value={type.value} key={type.value}>
        {type.name}
      </option>
    );
  };

  handleSubmit = e => {
    //* Prevent default form action.
    e.preventDefault();

    //* Validate the form
    if (this.validateForm()) {
      //* Create new istance of the field object and pass to parent func
      let updateObj = Object.assign({}, this.state.fields);

      //* Create Payment Summary
      updateObj['paymentSummary'] = this.createPaymentSummary();
      updateObj['totalAmount'] = this.state.totalAmount;
      updateObj['redirect'] = '/donation';

      this.props.updateFormData(updateObj);

      this.setState({ redirect: true });
    }
  };

  render() {
    return (
      <div>
        {/* Form redirect */}
        {this.state.redirect && <Redirect to="/payment" push />}

        <form onSubmit={this.handleSubmit}>
          {/* Form Title */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>Make a Donation</h2>
            </div>
          </div>

          <hr />

          {/* Donation Amount field */}
          <div className="form-group row">
            <label htmlFor="donationAmount" className="col-sm-3 col-form-label">
              Amount:
            </label>
            <div className="col-sm-7">
              <NumberFormat
                name="donationAmount"
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                placeholder="Enter amount."
                className="form-control"
                prefix={'$'}
                value={this.state.fields.donationAmount}
                // onChange={this.onChange}
                onValueChange={value => this.onUpdateDonationAmount(value.value)}
              />

              {this.state.errors.donationAmount &&
                <div className="errorMsg">
                  {this.state.errors.donationAmount}
                </div>}
            </div>
          </div>

          {/* ProjectType Field*/}
          <div className="form-group row">
            <label htmlFor="donationType" className="col-sm-3 col-form-label">
              ACA Donation Type:
            </label>
            <div className="col-sm-7">
              <select name="donationType" className="form-control" value={this.state.fields.donationType} onChange={this.onChange}>
                <option value="">--Please choose an option--</option>
                <option value="DONATION-FRP">Fish Program</option>
                <option value="DONATION-WRP">Wildlife Program</option>
                <option value="DONATION-LRP">Land Program</option>
                <option value="DONATION-COM">Communications Program</option>
              </select>
              {this.state.errors.donationType &&
                <div className="errorMsg">
                  {this.state.errors.donationType}
                </div>}
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="donationType" className="col-sm-3 col-form-label" />
            <div className="col-sm-7">
              <p>
                To designate your donation to a specific ACA project, please{` `}
                <a href="mailto:receivables@ab-conservation.com?subject=I would like to direct my donation to a specific ACA project." target="_blank">
                  <strong>email our Finance Department</strong>
                </a>. In your email, kindly specify the project you wish to support.
              </p>
            </div>
          </div>

          {/* isAdult checkbox*/}
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-3 col-form-label">
              Memorial Donation:
            </label>
            <div className="col-sm-7">
              <input name="isMemorialDonation" type="checkbox" checked={this.state.fields.isMemorialDonation} onChange={this.onChange} />
              {this.state.errors.isMemorialDonation &&
                <span className="errorMsg">
                  {' '}{this.state.errors.isMemorialDonation}
                </span>}
            </div>
          </div>

          {/* In Memory of full name field */}
          {this.state.fields.isMemorialDonation &&
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-3 col-form-label">
                Full Name:
              </label>
              <div className="col-sm-7">
                <input name="inMemoryOf" type="text" className="form-control" placeholder="Enter full name." value={this.state.fields.inMemoryOf} onChange={this.onChange} />
                {this.state.errors.inMemoryOf &&
                  <div className="errorMsg">
                    {this.state.errors.inMemoryOf}
                  </div>}
              </div>
            </div>}

          <hr />
          {/* Display Total Amount */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>
                Total: <NumberFormat displayType={'text'} name="donationAmount" decimalScale={0} fixedDecimalScale={true} prefix={'$'} value={this.state.fields.donationAmount} />
              </h2>
              {this.state.errors.totalAmount &&
                <div className="errorMsg">
                  {this.state.errors.totalAmount}
                </div>}
            </div>
          </div>

          {/* Submit button */}
          <div className="form-group row">
            <div className="col-sm-12">
              <button className="btn btn-primary" disabled={this.state.submitDisabled}>
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.form.formData
  };
};

export default connect(mapStateToProps, { updateFormData })(DonationForm);
