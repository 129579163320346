import React, { Component } from "react";
import validateNoviceShootForm from "../../validation/validateNoviceShootForm";
import isEmpty from "../../util/isEmpty";
import centsToDollarFormat from "../../util/centsToDollarFormat";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { updateFormData, setReturnURL } from "../../actions/formActions";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
const override = css`
  display: inline;
  margin: 15px;
`;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      nameAllowed: true,
      showDate1: false,
      showDate2: false,
      showDate3: false,
      showDate4: false,
      date1Remaining: 0,
      date2Remaining: 0,
      date3Remaining: 0,
      date4Remaining: 0,
      fields: {
        noviceShootDate: "",
        participantFirstName: "",
        participantLastName: "",
        participantAge: "",
        requirementAHEIA: false,
        requirementGBL: false,
        requirementPL: false,
        requirementNR: false,
        item1Description: "Novice Shoot Registration.",
        item1Code: "NH",
        item1Quantity: 1,
        item1Price: 5000, // In cents
        formName: "2024 Novice Shoot Registration",
      },
      totalAmount: 5000,
      errors: {},
      stripeTotalAmount: 5,
      stripeMetaData: {
        name: "",
      },
      stripeDescription: "",
      redirect: false,
    };
  }

  componentDidMount() {
    console.log("Component did mount!");
    axios
      .get(REACT_APP_API_URL + "inventory/TPF2024NS19AM")
      //* Evaluate received response
      .then((res) => {
        this.setState({
          showDate1: res.data.available,
          date1Remaining: res.data.itemsRemaining,
          is1Active: res.data.isActive,
        });
      });

    axios
      .get(REACT_APP_API_URL + "inventory/TPF2024NS19PM")
      //* Evaluate received response
      .then((res) => {
        this.setState({
          showDate2: res.data.available,
          date2Remaining: res.data.itemsRemaining,
          is2Active: res.data.isActive,
        });
        this.setState({ processing: false });
      });

    axios
      .get(REACT_APP_API_URL + "inventory/TPF2024NS20AM")
      //* Evaluate received response
      .then((res) => {
        this.setState({
          showDate3: res.data.available,
          date3Remaining: res.data.itemsRemaining,
          is3Active: res.data.isActive,
        });
        this.setState({ processing: false });
      });

    axios
      .get(REACT_APP_API_URL + "inventory/TPF2024NS20PM")
      //* Evaluate received response
      .then((res) => {
        this.setState({
          showDate4: res.data.available,
          date4Remaining: res.data.itemsRemaining,
          is4Active: res.data.isActive,
        });
        this.setState({ processing: false });
      });

    //* Update state if values exsist.
    if (this.props.form) {
      const formData = this.props.form;
      const formFieldState = this.state.fields;

      if (
        typeof formData.formName !== "undefined" &&
        formData.formName === formFieldState.formName
      ) {
        //* Create a complete object from current state
        let newObj = {};
        Object.keys(formFieldState).forEach((item) => {
          newObj[item] = formFieldState[item];
        });

        //* Update key data if within the prop object
        Object.keys(formData).forEach((item) => {
          if (newObj.hasOwnProperty(item)) {
            newObj[item] = formData[item];
          }
        });

        //* Update the state from the updated object
        this.setState((prevState, props) => ({
          fields: newObj,
        }));
      } //* TODO: Add Redirect to error page
    }
  }

  //* Form functions
  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  z;

  validateForm = () => {
    const validationCheck = validateNoviceShootForm(this.state);
    let formIsValid = false;

    //* Reset Field Error State
    this.setState({ errors: {} });

    //* Perform Validation
    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors,
        });
      }
    }
    return formIsValid;
  };

  createPaymentSummary = () => {
    var noviceShootDate = "";

    if (this.state.fields.noviceShootDate === "TPF2024NS19AM") {
      noviceShootDate = "Saturday, October 19th a.m.";
    }

    if (this.state.fields.noviceShootDate === "TPF2024NS19PM") {
      noviceShootDate = "Saturday, October 19th p.m.";
    }

    if (this.state.fields.noviceShootDate === "TPF2024NS20AM") {
      noviceShootDate = "Sunday, October 20th a.m.";
    }

    if (this.state.fields.noviceShootDate === "TPF2024NS20PM") {
      noviceShootDate = "Sunday, October 20th p.m.";
    }

    const paymentSummary = [
      {
        itemName: `Novice Hunt Registation: ${this.state.fields.participantFirstName} ${this.state.fields.participantLastName} for ${noviceShootDate}`,
        itemQuantity: 1,
        itemTotal: 5000,
        itemCode: this.state.fields.noviceShootDate,
      },
    ];
    return paymentSummary;
  };

  onCheckValidName = async () => {
    const firstName = this.state.fields.participantFirstName.trim();
    const lastName = this.state.fields.participantLastName.trim();

    try {
      const res = await axios.post(REACT_APP_API_URL + "checkNoviceName", {
        firstName: firstName,
        lastName: lastName,
      });

      if (res.data.nameAllowed) {
        this.setState({
          nameAllowed: true,
        });
        return true;
      } else {
        this.setState({
          nameAllowed: false,
        });
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  handleSubmit = async (e) => {
    //* Prevent default form action.
    e.preventDefault();

    const nameCheck = await this.onCheckValidName();

    console.log(nameCheck);

    //* Validate the form
    if (nameCheck && this.validateForm()) {
      //* Create new istance of the field object and pass to parent func
      let updateObj = Object.assign({}, this.state.fields);

      //* Create Payment Summary
      updateObj["paymentSummary"] = this.createPaymentSummary();
      updateObj["totalAmount"] = this.state.totalAmount;
      updateObj["redirect"] = "/noviceshoot";

      this.props.updateFormData(updateObj);
      this.props.setReturnURL(
        "https://www.ab-conservation.com/events/taber-pheasant-festival/novice-shoot/"
      );
      this.setState({ redirect: true });
    }
  };

  render() {
    return (
      <div>
        {/* Form redirect */}
        {this.state.redirect && <Redirect to="/payment" push />}
        {(this.state.is1Active ||
          this.state.is2Active ||
          this.state.is3Active ||
          this.state.is4Active) && (
          <form onSubmit={this.handleSubmit}>
            {/* Form Title */}
            <div className="form-group row mb0">
              <div className="col-sm-12">
                <h2>{this.state.fields.formName}</h2>
                {!this.state.nameAllowed && (
                  <div className="alert-danger alert">
                    <p></p>
                    <p>
                      The name you have entered has participated in the novice shoot more than three
                      times and is therefore ineligible to participate. If you believe this is an
                      error, please contact us at{" "}
                      <a href="mailto:info@ab-conservation.com">info@ab-conservation.com</a>.
                    </p>
                  </div>
                )}
                <p>
                  Please read the{" "}
                  <a
                    href="https://www.ab-conservation.com/events/taber-pheasant-festival/novice-shoot/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Novice Shoot webpage
                  </a>{" "}
                  if you haven't already.
                </p>
              </div>
            </div>

            <hr />

            {/* Novice Shoot Date Field */}

            {this.state.processing && (
              <PulseLoader
                css={override}
                sizeUnit={"px"}
                size={10}
                color={"#3775e4"}
                loading={this.state.processing}
              />
            )}

            {!this.state.processing && (
              <div className="form-group row mb0">
                <label htmlFor="noviceShootDate" className="col-sm-4 col-form-label">
                  <span className="requiredFields">*</span> Choose novice shoot date.
                </label>
                <div className="col-sm-8">
                  <select
                    name="noviceShootDate"
                    className="form-control mb5"
                    value={this.state.fields.noviceShootDate}
                    onChange={this.onChange}
                  >
                    <option value="">--Choose a date--</option>
                    {this.state.showDate1 & this.state.is1Active && (
                      <option value="TPF2024NS19AM">
                        Saturday, October 19; morning. {this.state.date1Remaining} spots remaining.
                      </option>
                    )}
                    {this.state.showDate2 & this.state.is2Active && (
                      <option value="TPF2024NS19PM">
                        Saturday, October 19; afternoon. {this.state.date2Remaining} spots
                        remaining.
                      </option>
                    )}
                    {this.state.showDate3 & this.state.is3Active && (
                      <option value="TPF2024NS20AM">
                        Sunday, October 20; morning. {this.state.date3Remaining} spots remaining.
                      </option>
                    )}
                    {this.state.showDate4 & this.state.is4Active && (
                      <option value="TPF2024NS20PM">
                        Sunday, October 20; afternoon. {this.state.date4Remaining} spots remaining.
                      </option>
                    )}
                  </select>
                  {this.state.errors.noviceShootDate && (
                    <div className="alert-danger alert">
                      <span className="errorMsg">{this.state.errors.noviceShootDate}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Participant full name field */}
            <div className="form-group row mb0">
              <label htmlFor="participantFirstName" className="col-sm-4 col-form-label">
                <span className="requiredFields">* </span>
                First Name:
              </label>
              <div className="col-sm-8">
                <input
                  name="participantFirstName"
                  type="text"
                  className="form-control mb5"
                  placeholder="Enter participant's first name."
                  value={this.state.fields.participantFirstName}
                  onChange={this.onChange}
                />
                {this.state.errors.participantFirstName && (
                  <div className="alert-danger alert">
                    <span className="errorMsg">{this.state.errors.participantFirstName}</span>
                  </div>
                )}
              </div>
            </div>

            {/* Participant last name field */}
            <div className="form-group row mb0">
              <label htmlFor="participantLastName" className="col-sm-4 col-form-label">
                <span className="requiredFields">* </span>Last Name:
              </label>
              <div className="col-sm-8 mb0">
                <input
                  name="participantLastName"
                  type="text"
                  className="form-control mb5"
                  placeholder="Enter participant's last name."
                  value={this.state.fields.participantLastName}
                  onChange={this.onChange}
                />
                {this.state.errors.participantLastName && (
                  <div className="alert-danger alert">
                    <span className="errorMsg">{this.state.errors.participantLastName}</span>
                  </div>
                )}
              </div>
            </div>

            {/* Participant last name field */}
            <div className="form-group row mb0">
              <label htmlFor="participantAge" className="col-sm-4 col-form-label">
                <span className="requiredFields">* </span>Age:
              </label>
              <div className="col-sm-8">
                <input
                  name="participantAge"
                  type="text"
                  className="form-control mb5"
                  placeholder="Enter participant's age."
                  value={this.state.fields.participantAge}
                  onChange={this.onChange}
                />
                {this.state.errors.participantAge && (
                  <div className="alert-danger alert">
                    <span className="errorMsg">{this.state.errors.participantAge}</span>
                  </div>
                )}
                <p className="formNote">
                  <i>
                    If your child is under the age of 10 and cannot get licensing yet and you would
                    like to know more about the Novice Shoot, please contact Julie at 403-382-8568.
                  </i>
                </p>
              </div>
            </div>

            {/* Requirement checkboxs*/}
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-4 col-form-label">
                <span className="requiredFields">* </span>Confirm Requirements:
              </label>
              <div className="col-sm-8">
                <div>
                  <span className="checkboxMultilineIndent">
                    <input
                      name="requirementAHEIA"
                      type="checkbox"
                      checked={this.state.fields.requirementAHEIA}
                      onChange={this.onChange}
                    />
                    &nbsp; The participant will have completed the{" "}
                    <a href="http://www.aheia.com/" target="_blank" rel="noopener noreferrer">
                      Alberta Hunter Education Training Course
                    </a>{" "}
                    by their novice shoot day.
                  </span>
                </div>
                <div>
                  {this.state.errors.requirementAHEIA && (
                    <div className="alert-danger alert">
                      <span className="errorMsg"> {this.state.errors.requirementAHEIA}</span>
                    </div>
                  )}
                </div>
                <div>
                  <span className="checkboxMultilineIndent">
                    <input
                      name="requirementGBL"
                      type="checkbox"
                      checked={this.state.fields.requirementGBL}
                      onChange={this.onChange}
                    />
                    <span>
                      &nbsp; The participant will have a valid game bird licence by their novice
                      hunt day.
                    </span>
                  </span>
                </div>
                <div>
                  {this.state.errors.requirementGBL && (
                    <div className="alert-danger alert">
                      <span className="errorMsg"> {this.state.errors.requirementGBL}</span>
                    </div>
                  )}
                </div>
                <div>
                  <span className="checkboxMultilineIndent">
                    <input
                      name="requirementPL"
                      type="checkbox"
                      checked={this.state.fields.requirementPL}
                      onChange={this.onChange}
                    />
                    <span>
                      &nbsp; The participant will have a valid pheasant licence by their novice hunt
                      day.
                    </span>
                  </span>
                </div>
                <div>
                  {this.state.errors.requirementPL && (
                    <div className="alert-danger alert">
                      <span className="errorMsg"> {this.state.errors.requirementPL}</span>
                    </div>
                  )}
                </div>
                <div>
                  <span className="checkboxMultilineIndent">
                    <input
                      name="requirementNR"
                      type="checkbox"
                      checked={this.state.fields.requirementNR}
                      onChange={this.onChange}
                    />
                    <span>
                      &nbsp; I acknowledge that no refunds will be issued after noon on Oct 16th
                      2024.
                    </span>
                  </span>
                </div>
                <div>
                  {this.state.errors.requirementNR && (
                    <div className="alert-danger alert">
                      <span className="errorMsg"> {this.state.errors.requirementNR}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <hr />
            <div className="form-group row">
              <div className="col-sm-12">
                If you are registering more than one child, you must fill out the form again.{" "}
                <strong>Your $50 will be refunded at the event.</strong>
              </div>
            </div>

            {/* Display Total Amount */}
            <div className="form-group row">
              <div className="col-sm-12">
                <h2>Total: {centsToDollarFormat(this.state.totalAmount)}</h2>
                {this.state.errors.totalAmount && (
                  <div className="errorMsg">{this.state.errors.totalAmount}</div>
                )}
              </div>
            </div>

            {/* Submit button */}
            <div className="form-group row">
              <div className="col-sm-12">
                {!this.state.processing && (
                  <button className="btn btn-primary" disabled={this.state.submitDisabled}>
                    Make Payment
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form.formData,
  };
};

export default connect(
  mapStateToProps,
  { updateFormData, setReturnURL }
)(Template);
