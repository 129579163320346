import React, { Component } from 'react';
import validateTemplateForm from '../../validation/validateTemplateForm';
import isEmpty from '../../util/isEmpty';
import centsToDollarFormat from '../../util/centsToDollarFormat';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { updateFormData } from '../../actions/formActions';

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
        isAdult: false,
        item1Description: 'This is a description for Item 1.',
        item1Code: 'Item1',
        item1Quantity: 0,
        item1Price: 10000, // In cents
        item2Description: 'This is a description for Item 2.',
        item2Code: 'Item2',
        item2Quantity: 0,
        item2Price: 20000, // In cents
        formName: 'Template'
      },
      totalAmount: 0,
      errors: {},
      stripeTotalAmount: 5,
      stripeMetaData: {
        name: ''
      },
      stripeDescription: '',
      redirect: false
    };
  }

  componentDidMount() {
    //* Update state if values exsist.

    if (this.props.form) {
      const formData = this.props.form;
      const formFieldState = this.state.fields;

      if (
        typeof formData.formName !== 'undefined' &&
        formData.formName === formFieldState.formName
      ) {
        //* Create a complete object from current state
        let newObj = {};
        Object.keys(formFieldState).forEach(item => {
          newObj[item] = formFieldState[item];
        });

        //* Update key data if within the prop object
        Object.keys(formData).forEach(item => {
          if (newObj.hasOwnProperty(item)) {
            newObj[item] = formData[item];
          }
        });

        //* Update the state from the updated object
        this.setState(
          (prevState, props) => ({
            fields: newObj
          }),
          () => {
            this.updateTotalAmount();
          }
        );
      } //* TODO: Add Redirect to error page
    }
  }

  //* Form functions
  onChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      prevState => ({
        fields: {
          ...prevState.fields,
          [name]: value
        }
      }),
      () => {
        this.updateTotalAmount();
      }
    );
  };

  updateTotalAmount = () => {
    const item1Total =
      this.state.fields.item1Price * this.state.fields.item1Quantity;
    const item2Total =
      this.state.fields.item2Price * this.state.fields.item2Quantity;
    const totalAmount = item1Total + item2Total;
    this.setState({ totalAmount: totalAmount });
  };

  validateForm = () => {
    const validationCheck = validateTemplateForm(this.state);
    let formIsValid = false;

    //* Reset Field Error State
    this.setState({ errors: {} });

    //* Perform Validation
    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors
        });
      }
    }
    return formIsValid;
  };

  createPaymentSummary = () => {
    const paymentSummary = `(${this.state.fields.item1Code} x ${
      this.state.fields.item1Quantity
    }) + (${this.state.fields.item2Code} x ${
      this.state.fields.item2Quantity
    }) = Total ${centsToDollarFormat(this.state.totalAmount)}`;
    return paymentSummary;
  };

  handleSubmit = e => {
    //* Prevent default form action.
    e.preventDefault();

    //* Validate the form
    if (this.validateForm()) {
      //* Create new istance of the field object and pass to parent func
      let updateObj = Object.assign({}, this.state.fields);

      //* Create Payment Summary
      updateObj['paymentSummary'] = this.createPaymentSummary();
      updateObj['totalAmount'] = this.state.totalAmount;
      updateObj['redirect'] = '/template';

      this.props.updateFormData(updateObj);

      this.setState({ redirect: true });
    }
  };

  render() {
    return (
      <div>
        {/* Form redirect */}
        {this.state.redirect && <Redirect to="/payment" push />}

        <form onSubmit={this.handleSubmit}>
          {/* Form Title */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>{this.state.fields.formName} Purchase Form</h2>
            </div>
          </div>

          <hr />

          {/* Participant full name field */}
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-5 col-form-label">
              Name:
            </label>
            <div className="col-sm-7">
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="Enter participant's full name."
                value={this.state.fields.name}
                onChange={this.onChange}
              />
              {this.state.errors.name && (
                <div className="errorMsg">{this.state.errors.name}</div>
              )}
            </div>
          </div>

          {/* isAdult checkbox*/}
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-5 col-form-label">
              Are you an adult:
            </label>
            <div className="col-sm-7">
              <input
                name="isAdult"
                type="checkbox"
                checked={this.state.fields.isAdult}
                onChange={this.onChange}
              />
              {this.state.errors.isAdult && (
                <span className="errorMsg"> {this.state.errors.isAdult}</span>
              )}
            </div>
          </div>

          {/* item1Quantity Select Box */}
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-5 col-form-label">
              Item 1 Quantity (
              {centsToDollarFormat(this.state.fields.item1Price)}
              ):
            </label>
            <div className="col-sm-7">
              <select
                name="item1Quantity"
                className="form-control"
                value={this.state.fields.item1Quantity}
                onChange={this.onChange}
              >
                <option value="0">--Please choose a quantity--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              {this.state.errors.item1Quantity && (
                <div className="errorMsg">
                  {this.state.errors.item1Quantity}
                </div>
              )}
            </div>
          </div>

          {/* item2Quantity Select Box */}
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-5 col-form-label">
              Item 1 Quantity (
              {centsToDollarFormat(this.state.fields.item2Price)}
              ):
            </label>
            <div className="col-sm-7">
              <select
                name="item2Quantity"
                className="form-control"
                value={this.state.fields.item2Quantity}
                onChange={this.onChange}
              >
                <option value="0">--Please choose a quantity--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              {this.state.errors.item2Quantity && (
                <div className="errorMsg">
                  {this.state.errors.item2Quantity}
                </div>
              )}
            </div>
          </div>

          <hr />

          {/* Display Total Amount */}
          <div className="form-group row">
            <div className="col-sm-12">
              <h2>Total: {centsToDollarFormat(this.state.totalAmount)}</h2>
              {this.state.errors.totalAmount && (
                <div className="errorMsg">{this.state.errors.totalAmount}</div>
              )}
            </div>
          </div>

          {/* Submit button */}
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn-primary"
                disabled={this.state.submitDisabled}
              >
                Make Payment
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.form.formData
  };
};

export default connect(
  mapStateToProps,
  { updateFormData }
)(Template);
