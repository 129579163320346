const validateNWSGCForm = (state) => {
  let fields = state.fields;
  let response = { formIsValid: true, errors: {} };

  if (fields['registrationType'] === 'none') {
    response.formIsValid = false;
    response.errors['registrationType'] =
      '* You must choose a registration type.';
  }

  return response;
};

export default validateNWSGCForm;
