const Validator = require("validator");

const validateWaterfowlWarmupForm = (state) => {
  let fields = state.fields;
  let response = { formIsValid: true, errors: {} };

  if (fields["firstName"] === "") {
    response.formIsValid = false;
    response.errors["firstName"] = "* You must enter your first name.";
  }

  if (fields["lastName"] === "") {
    response.formIsValid = false;
    response.errors["lastName"] = "* You must enter your last name.";
  }

  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(fields["email"])) {
    response.formIsValid = false;
    response.errors["email"] = "* You must enter an email.";
  }

  if (fields["phoneNumber"] === "") {
    response.formIsValid = false;
    response.errors["phoneNumber"] = "* You must enter a phone number.";
  }

  if (fields["teamName"] === "") {
    response.formIsValid = false;
    response.errors["teamName"] = "* You must enter a team name.";
  }

  if (fields["sponsorshipLevel"] === "") {
    response.formIsValid = false;
    response.errors["sponsorshipLevel"] = "* You must choose a sponsorship level.";
  }

  return response;
};

export default validateWaterfowlWarmupForm;
