const Validator = require("validator");

const validateNoviceShootForm = (state) => {
  let fields = state.fields;
  let response = { formIsValid: true, errors: {} };

  if (Validator.isEmpty(fields["noviceShootDate"])) {
    response.formIsValid = false;
    response.errors["noviceShootDate"] = "* Please choose a date.";
  }

  if (Validator.isEmpty(fields["participantFirstName"])) {
    response.formIsValid = false;
    response.errors["participantFirstName"] = "* Please enter participants first name.";
  }

  if (Validator.isEmpty(fields["participantLastName"])) {
    response.formIsValid = false;
    response.errors["participantLastName"] = "* Please enter participants last name.";
  }

  if (!fields["requirementAHEIA"]) {
    response.formIsValid = false;
    response.errors["requirementAHEIA"] =
      "* Participant must have completed the Alberta Hunter Education Training Course.";
  }

  if (!fields["requirementGBL"]) {
    response.formIsValid = false;
    response.errors["requirementGBL"] =
      "* Participant requires a valid game bird licence by their novice hunt day.";
  }

  if (!fields["requirementPL"]) {
    response.formIsValid = false;
    response.errors["requirementPL"] =
      "* Participant requires a valid pheasant licence by their novice hunt day.";
  }

  if (!fields["requirementNR"]) {
    response.formIsValid = false;
    response.errors["requirementNR"] = "* You must agree to the no-refund policy.";
  }

  if (!Validator.isInt(fields["participantAge"], { min: 10 })) {
    response.formIsValid = false;
    response.errors["participantAge"] = "* Please enter a number greater than 10.";
  }

  return response;
};

export default validateNoviceShootForm;
