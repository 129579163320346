export const donationTypes = {
  fish: [
    {
      name: 'Fish Program',
      value: 'DONATION-FRP',
      defualtAmount: 20
    },
    {
      name: 'Community Fish Stocking',
      value: 'DONATION-CFS',
      defualtAmount: 20
    },
    {
      name: 'East Slopes Fisheries Inventory',
      value: 'DONATION-ESFI',
      defualtAmount: 20
    },
    {
      name: 'Fish Barriers',
      value: 'DONATION-FB',
      defualtAmount: 20
    },
    {
      name: 'Hasse Lake Fisheries Restoration',
      value: 'DONATION-HLFR',
      defualtAmount: 20
    },
    { name: 'Lake Aeration', value: 'la', defualtAmount: 20, category: 'Fish' },
    {
      name: 'Westslope Cutthroat Trout Protection',
      value: 'DONATION-WCTP',
      defualtAmount: 20
    }
  ],
  wildlife: [
    {
      name: 'Wildlife Program',
      value: 'DONATION-WRP',
      defualtAmount: 20
    },
    {
      name: 'Enchant Farm',
      value: 'DONATION-EF',
      defualtAmount: 20
    },
    {
      name: 'MULTISAR',
      value: 'DONATION-MULTI',
      defualtAmount: 20
    },
    {
      name: 'Pheasant Raise and Release',
      value: 'DONATION-PRR',
      defualtAmount: 20,
      category: 'Wildlife'
    },
    {
      name: 'Provincial Pheasant Release Program',
      value: 'DONATION-PPRP',
      defualtAmount: 20,
      category: 'Wildlife'
    },
    {
      name: 'Pronghorn Projects',
      value: 'DONATION-PP',
      defualtAmount: 20,
      category: 'Wildlife'
    }
  ],
  land: [
    {
      name: 'Land Program',
      value: 'DONATION-LRP',
      defualtAmount: 20
    },
    {
      name: 'Fisheries Access Site Management',
      value: 'DONATION-FASM',
      defualtAmount: 20
    },
    {
      name: 'Riparian Conservation',
      value: 'DONATION-RC',
      defualtAmount: 20
    }
  ],
  communications: [
    {
      name: 'Communications Program',
      value: 'DONATION-COM',
      defualtAmount: 20
    },
    {
      name: 'Wildlife Cameras',
      value: 'DONATION-WC',
      defualtAmount: 20
    },
    {
      name: 'Kids Can Catch',
      value: 'DONATION-KCC',
      defualtAmount: 20
    }
  ]
};

export const getDonationTypeName = value => {
  const keys = Object.values(donationTypes);

  for (var x = 0; x < keys.length; x++) {
    let next = keys[x];
    for (var y = 0; y < keys[x].length; y++) {
      if (next[y].value === value) {
        return next[y].name;
      }
    }
  }
};
