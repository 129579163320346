import { UPDATE_FORMDATA } from './types';
import { SET_RETURN_URL } from './types';
import { SET_RETURN_BUTTON_TEXT } from './types';
import { CLEAR_FORMDATA } from './types';
import { SET_ERROR_MSG } from './types';

export const updateFormData = (formData) => {
  return {
    type: UPDATE_FORMDATA,
    payload: formData,
  };
};

export const setReturnURL = (returnURL) => {
  return {
    type: SET_RETURN_URL,
    payload: returnURL,
  };
};

export const setReturnButtonText = (returnButtonText) => {
  return {
    type: SET_RETURN_BUTTON_TEXT,
    payload: returnButtonText,
  };
};

export const setErrorMsg = (errorMsg) => {
  return {
    type: SET_ERROR_MSG,
    payload: errorMsg,
  };
};

export const clearFormData = () => {
  return {
    type: CLEAR_FORMDATA,
  };
};
