import React, { Component } from "react";
import { injectStripe } from "react-stripe-elements";
import Reaptcha from "reaptcha";
import { compose } from "redux";
import { connect } from "react-redux";
import { CardElement } from "react-stripe-elements";
import isEmpty from "../util/isEmpty";
import axios from "axios";
import validatePaymentForm from "../validation/validatePaymentForm";
import { withRouter } from "react-router";
import { Redirect } from "react-router";
import centsToDollarFormat from "../util/centsToDollarFormat";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../css/phone_input.css";
import { setErrorMsg, setReturnURL, setReturnButtonText } from "../actions/formActions";

import { css } from "@emotion/core";
import { PulseLoader } from "react-spinners";

import "../css/form.css";

const override = css`
  display: inline;
  margin: 15px;
`;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_GOOGLE_CAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      appState: "working",
      appStateMsg: "",
      fields: {
        name: "",
        email: "",
        phone: "",
        address_line1: "",
        address_line2: "",
        address_city: "",
        address_state: "",
        address_zip: "",
        address_country: "",
        name_ship: "",
        email_ship: "",
        phone_ship: "",
        address_line1_ship: "",
        address_line2_ship: "",
        address_city_ship: "",
        address_state_ship: "",
        address_zip_ship: "",
        address_country_ship: "",

        cardFieldErrors: {},
        cardFieldComplete: false,
        captchaVerified: false,
        showShippingInfo: false,
        confirmIndividual: false,
      },
      discountValid: false,
      discountAvailable: false,
      discountsRemaining: 0,
      discountCode: "",
      returnURL: "http://www.ab-conservation.com",
      returnButtonText: "Return to form",
      isRegistration: "",
      registrationType: "",
      submitButtonText: "Complete Payment",
      billingInformationText: "Billing Information",
      shippingInformationText: "Shipping Information",
      abVerify: false,
      currency: "USD",
      dollars: "",
      errors: {},
      formVisible: true,
      submitButtonVisible: true,
      description: [],
      redirect: false,
      captchaLoaded: false,
      redirectURL: "",
      formRedirect: "",
      submitDisabled: false,
      captchaResponse: "",
      chargeStatus: "pending",
      redirectError: false,
      redirectSuccess: false,
      paymentSummary: [],
    };
  }

  componentDidMount() {
    console.log(REACT_APP_API_URL);
    //* Set incoming params

    if (this.props.form) {
      //* Set description/amount/returnURL
      const formData = this.props.form;

      if (!!formData.abVerify) {
        this.setState({ abVerify: formData.abVerify });
      }

      if (!!formData.totalAmount || !!formData.paymentSummary) {
        // Set amount/description/
        const description = formData.paymentSummary;
        const amount = formData.totalAmount;
        const formRedirect = formData.redirect;

        // Registation
        if (
          typeof formData.registrationType !== "undefined" ||
          formData.registrationType !== null
        ) {
          this.setState({
            isRegistration: true,
            registrationType: formData.registrationType,
          });
        }

        // Discount Code
        if (!!formData.discountCode) {
          // Set the discount code
          this.setState({
            discountCode: formData.discountCode,
          });

          // Check Validity
          axios
            .get(REACT_APP_API_URL + "discountCode/verify/" + formData.discountCode)
            // Evaluate Response
            .then((res) => {
              if (res.data.valid) {
                this.setState({
                  discountValid: res.data.valid,
                  discountAvailable: res.data.available,
                  discountsRemaining: res.data.itemsRemaining,
                });
                if (res.data.available) {
                  this.setState({
                    fields: {
                      ...this.state.fields,
                      amount: 0,
                      dollars: centsToDollarFormat(0),
                    },
                    billingInformationText: "Registration Information",
                  });
                }
              } else {
                this.setState({
                  discountValid: false,
                  discountAvailable: false,
                  discountsRemaining: 0,
                });
              }
            })
            .catch((err) =>
              this.setState({
                discountValid: false,
                discountAvailable: false,
                discountsRemaining: 0,
              })
            );
        } else {
          // Invalid Discount Code
        }

        this.setState({
          fields: {
            ...this.state.fields,
            description: description,
            amount: amount,
            dollars: centsToDollarFormat(amount),
          },
          description: description,
          returnURL: formRedirect,
          paymentSummary: formData.paymentSummary,
        });
      } else {
        this.props.setErrorMsg("There was an error accessing the payment form. (Err 01)");

        this.setState({
          redirectError: true,
        });
      }
    } else {
      console.log("boom2");
      this.props.setErrorMsg("There was an error accessing the payment form. (Err 02)");

      this.setState({
        redirectError: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //* Captcha functions
  onVerifyCaptcha = (res) => {
    this.setState({
      fields: {
        ...this.state.fields,
        captchaVerified: true,
      },
      captchaResponse: res,
    });
  };

  recaptchaLoaded = () => {
    this.setState({ formVisible: true });
  };

  //* Form functions
  onChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  };

  validateCardElement = (object) => {
    this.setState({
      fields: {
        ...this.state.fields,
        cardFieldComplete: object.complete,
        cardFieldErrors: object.error,
      },
    });
  };

  validateForm = () => {
    const validationCheck = validatePaymentForm(this.state);
    let formIsValid = false;

    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors,
        });
      }
    }
    return formIsValid;
  };

  handleSubmit = (e) => {
    //* Prevent default form action.
    e.preventDefault();

    //* Server side form validation.
    if (this.validateForm(this.state)) {
      //* Hide the submit button.
      this.setState({ processing: true });
      this.props.setReturnURL(this.state.returnURL);
      this.props.setReturnButtonText(this.state.returnButtonText);

      let customerName = "";
      // Set the proper name.
      if (!this.state.fields.showShippingInfo) {
        customerName = this.state.fields.name;
      } else {
        customerName = this.state.fields.name_ship;
      }

      if (this.state.discountAvailable) {
        // Process Discount Purchase
        axios
          .post(REACT_APP_API_URL + "discount", {
            name: customerName,
            address_line1: this.state.fields.address_line1,
            address_line2: this.state.fields.address_line2,
            address_city: this.state.fields.address_city,
            address_state: this.state.fields.address_state,
            address_zip: this.state.fields.address_zip,
            address_country: this.state.fields.address_country,
            captchaResponse: this.state.captchaResponse,
            discountCode: this.state.discountCode,
            email: this.state.fields.email,
            registrationType: "NWSGC2020",
          })
          //* Evaluate received response
          .then((res) => {
            const chargeStatus = res.data.chargeSucceeded;

            //* Credit card successfully charged.
            if (chargeStatus) {
              //TODO: Need to reset form.

              //* Hide the form and show success message
              this.props.setErrorMsg(
                "Transaction successful. You will receive an email receipt shortly. Thank you."
              );
              this.setState({
                redirectSuccess: true,
              });
            } else {
              //* Hide the form and show error message
              this.props.setErrorMsg(res.data.error);
              this.setState({
                redirectError: true,
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        //* Create stripe token for card validation.
        this.props.stripe
          .createToken({
            name: this.state.fields.name,
            address_line1: this.state.fields.address_line1,
            address_line2: this.state.fields.address_line2,
            address_city: this.state.fields.address_city,
            address_state: this.state.fields.address_state,
            address_zip: this.state.fields.address_zip,
            address_country: this.state.fields.address_country,
            currency: this.state.fields.currency,
          })
          //* Token created successfully.
          .then(({ token }) => {
            // Attempt to charge the card.
            axios
              .post(REACT_APP_API_URL + "charge", {
                id: token.id,
                brand: token.card.brand,
                description: `${this.createSubmissionDescription(this.state.description)} * em: ${
                  this.state.fields.email
                }`,
                amount: this.state.fields.amount,
                captchaResponse: this.state.captchaResponse,
                receipt_email: this.state.fields.email,
                metadata: {
                  description: `${this.createSubmissionDescription(this.state.description)} * em: ${
                    this.state.fields.email
                  }`,
                },
                itemInventoryArray: this.createItemInventoryArray(),

                // Registration
                isRegistration: this.state.isRegistration,
                registrationType: this.state.registrationType,

                // Customer Information
                name: this.state.fields.name,
                email: this.state.fields.email,
                phone: this.state.fields.phone,
                address_line1: this.state.fields.address_line1,
                address_line2: this.state.fields.address_line2,
                address_city: this.state.fields.address_city,
                address_state: this.state.fields.address_state,
                address_zip: this.state.fields.address_zip,
                address_country: this.state.fields.address_country,
                discountCode: this.state.discountCode,
                useShippingInfo: this.state.fields.showShippingInfo,
                name_ship: this.state.fields.name_ship,
                email_ship: this.state.fields.email_ship,
                phone_ship: this.state.fields.phone_ship,
                address_line1_ship: this.state.fields.address_line1_ship,
                address_line2_ship: this.state.fields.address_line2_ship,
                address_city_ship: this.state.fields.address_city_ship,
                address_state_ship: this.state.fields.address_state_ship,
                address_zip_ship: this.state.fields.address_zip_ship,
                address_country_ship: this.state.fields.address_country_ship,
              })
              //* Evaluate received response
              .then((res) => {
                const chargeStatus = res.data.chargeSucceeded;

                //* Credit card successfully charged.
                if (chargeStatus) {
                  //TODO: Need to reset form.

                  //* Hide the form and show success message
                  this.props.setErrorMsg(
                    "Transaction successful. You will receive an email receipt shortly. Thank you."
                  );
                  this.setState({
                    redirectSuccess: true,
                  });
                } else {
                  //* Hide the form and show error message
                  this.props.setErrorMsg(res.data.error);
                  this.setState({
                    redirectError: true,
                  });
                }
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            //* Hide the form and show error message
            this.props.setErrorMsg("There was a problem charging your card. (Err 03)");
            console.log(err);
            this.setState({
              redirectError: true,
            });
          });
      }
    }
  };

  stripeElementChange = (element, name) => {
    if (!element.empty && element.complete) {
      this.setState({ [name]: true });
    }
    this.checkEmptyStripeElements();
  };

  createSubmissionDescription = (description) => {
    let submissionDescription = "";
    description.forEach((item) => {
      submissionDescription =
        submissionDescription +
        `* ${item.itemQuantity} x ${item.itemName} = ${centsToDollarFormat(item.itemTotal)} `;
    });

    submissionDescription =
      submissionDescription + `* TOTAL: ${centsToDollarFormat(this.state.fields.amount)} `;

    if (this.state.fields.showShippingInfo) {
      let shippingInformation =
        `* Ship ` +
        `* N: ` +
        this.state.fields.name_ship +
        ` ` +
        `* Ph: ` +
        this.state.fields.phone_ship +
        ` ` +
        `* C: ` +
        this.state.fields.address_city_ship +
        ` ` +
        `* Pr: ` +
        this.state.fields.address_state_ship;

      submissionDescription = submissionDescription + shippingInformation;
    } else {
      submissionDescription =
        submissionDescription +
        `* Ship same as bill. * N: ` +
        this.state.fields.name +
        ` * Ph: ` +
        this.state.fields.phone +
        ` ` +
        `* C: ` +
        this.state.fields.address_city +
        ` ` +
        `* Pr: ` +
        this.state.fields.address_state;
    }
    return submissionDescription;
  };

  createItemInventoryArray = () => {
    let ItemInventoryArray = [];

    this.props.form.paymentSummary.forEach((element) => {
      let newObj = {
        itemCode: element.itemCode,
        amount: element.itemTotal,
        quantity: element.itemQuantity,
      };

      ItemInventoryArray.push(newObj);
    });

    return ItemInventoryArray;
  };

  toggleShippingInformation = () => {
    this.setState((prevState) => {
      return {
        fields: {
          ...this.state.fields,
          showShippingInfo: !prevState.fields.showShippingInfo,
        },
      };
    });
  };

  toggleConfirmIndividual = () => {
    this.setState((prevState) => {
      return {
        fields: {
          ...this.state.fields,
          confirmIndividual: !prevState.fields.confirmIndividual,
        },
      };
    });
  };

  formatPhoneNumber = (phoneNumber) => {
    let cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
    }
    return "";
  };

  render(props) {
    let description = this.state.description.map((item) => (
      <li key={item.itemName}>
        {item.itemQuantity} x {item.itemName} = {centsToDollarFormat(item.itemTotal)}
      </li>
    ));

    let discountText = "";
    if (this.state.discountCode !== "") {
      if (!this.state.discountValid) {
        discountText = ` ` + this.state.discountCode + ` (INVALID CODE)`;
      } else {
        discountText =
          ` ` + this.state.discountCode + ` (${this.state.discountsRemaining} discounts remaining)`;
      }
    }

    return (
      <div>
        {this.state.redirectSuccess && <Redirect to="/success" push />}
        {this.state.redirectError && <Redirect to="/error" push />}
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <div className="col-sm-12">
              {/* <AddressSection /> */}
              <h1>Payment Details:</h1>
              <hr />
              {this.state.description && (
                <div>
                  <div className="alert alert-primary" role="alert">
                    <h4>Description:</h4>
                    <div>
                      <ul>{description}</ul>

                      {this.state.discountCode && (
                        <div>
                          <p>
                            <strong>DISCOUNT CODE:</strong>
                            {discountText}
                          </p>
                        </div>
                      )}
                      <div>
                        TOTAL: <strong>{centsToDollarFormat(this.state.fields.amount)}</strong>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={this.state.processing}
                      onClick={() => this.props.history.push(this.state.returnURL)}
                    >
                      Edit Items
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!this.state.discountAvailable && (
            <div>
              <hr />

              <div className="form-group row">
                <div className="col-sm-12">
                  <input
                    name="isAdult"
                    type="checkbox"
                    checked={this.state.showShippingInfo}
                    onChange={this.toggleShippingInformation}
                  />{" "}
                  Shipping information is different than billing information.
                </div>
              </div>
            </div>
          )}

          {this.state.fields.showShippingInfo && (
            <div>
              <hr />
              <div className="form-group row">
                <div className="col-sm-12">
                  <h4>{this.state.shippingInformationText}:</h4>
                </div>
                <label htmlFor="email" className="col-sm-3 col-form-label">
                  Shipping Full Name:
                </label>
                <div className="col-sm-9">
                  <input
                    name="name_ship"
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.fields.name_ship}
                    onChange={this.onChange}
                  />
                  {this.state.errors.name_ship && (
                    <div className="errorMsg">{this.state.errors.name_ship}</div>
                  )}
                </div>
              </div>

              {/* Shipping Email Field*/}
              <div className="form-group row">
                <label htmlFor="email_ship" className="col-sm-3 col-form-label">
                  Shipping Email:
                </label>
                <div className="col-sm-9">
                  <input
                    name="email_ship"
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.fields.email_ship}
                    onChange={this.onChange}
                  />
                  {this.state.errors.email_ship && (
                    <div className="errorMsg">{this.state.errors.email_ship}</div>
                  )}
                </div>
              </div>

              {/* Shipping Phone */}
              <div className="form-group row">
                <label htmlFor="phone" className="col-sm-3 col-form-label">
                  Shipping Phone Number:
                </label>
                <div className="col-sm-9">
                  <PhoneInput
                    placeholder={"123-456-789"}
                    inputClass={"phoneInput"}
                    disableDropdown={true}
                    disableCountryCode={true}
                    disableSearchIcon={true}
                    countryCodeEditable={true}
                    country={"ca"}
                    buttonClass={"display:none"}
                    onlyCountries={["ca"]}
                    defaultMask={"...-...-...."}
                    alwaysDefaultMask={true}
                    value={this.state.phone_ship}
                    onChange={(phone_ship) => {
                      let fields = this.state.fields;
                      fields.phone_ship = this.formatPhoneNumber(phone_ship);
                      this.setState({
                        fields,
                      });
                    }}
                  />

                  {this.state.errors.phone_ship && (
                    <div className="errorMsg">{this.state.errors.phone_ship}</div>
                  )}
                </div>
              </div>

              {/* Shipping Address1 Field */}
              <div className="form-group row">
                <label htmlFor="address_line1_ship" className="col-sm-3 col-form-label">
                  Shipping Address:
                </label>
                <div className="col-sm-9">
                  <input
                    name="address_line1_ship"
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.fields.address_line1_ship}
                    onChange={this.onChange}
                  />
                  {this.state.errors.address_line1_ship && (
                    <div className="errorMsg">{this.state.errors.address_line1_ship}</div>
                  )}
                </div>
              </div>

              {/* Shipping Address2 Field */}
              <div className="form-group row">
                <label htmlFor="address_line2_ship" className="col-sm-3 col-form-label">
                  Shipping Address 2:
                </label>
                <div className="col-sm-9">
                  <input
                    name="address_line2_ship"
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.fields.address_line2_ship}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              {/* Shipping City Field */}
              <div className="form-group row">
                <label htmlFor="address_city_ship" className="col-sm-3 col-form-label">
                  Shipping City:
                </label>
                <div className="col-sm-9">
                  <input
                    name="address_city_ship"
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.fields.address_city_ship}
                    onChange={this.onChange}
                  />
                  {this.state.errors.address_city_ship && (
                    <div className="errorMsg">{this.state.errors.address_city_ship}</div>
                  )}
                </div>
              </div>

              {/* Shipping Province Field */}
              <div className="form-group row">
                <label htmlFor="address_state_ship" className="col-sm-3 col-form-label">
                  Shipping Province:
                </label>
                <div className="col-sm-9">
                  <input
                    name="address_state_ship"
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.fields.address_state_ship}
                    onChange={this.onChange}
                  />
                  {this.state.errors.address_state_ship && (
                    <div className="errorMsg">{this.state.errors.address_state_ship}</div>
                  )}
                </div>
              </div>

              {/* Shipping Postal Field */}
              <div className="form-group row">
                <label htmlFor="address_zip_ship" className="col-sm-3 col-form-label">
                  Shipping Postal:
                </label>
                <div className="col-sm-9">
                  <input
                    name="address_zip_ship"
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.fields.address_zip_ship}
                    onChange={this.onChange}
                  />
                  {this.state.errors.address_zip_ship && (
                    <div className="errorMsg">{this.state.errors.address_zip_ship}</div>
                  )}
                </div>
              </div>

              {/* Shipping Country Field */}
              <div className="form-group row">
                <label htmlFor="address_country_ship" className="col-sm-3 col-form-label">
                  Shipping Country:
                </label>
                <div className="col-sm-9">
                  <input
                    name="address_country_ship"
                    type="text"
                    placeholder=""
                    className="form-control"
                    value={this.state.fields.address_country_ship}
                    onChange={this.onChange}
                  />
                  {this.state.errors.address_country_ship && (
                    <div className="errorMsg">{this.state.errors.address_country_ship}</div>
                  )}
                </div>
              </div>
            </div>
          )}

          <hr />

          {/* Name Field*/}
          <div className="form-group row">
            <div className="col-sm-12">
              <h4>{this.state.billingInformationText}:</h4>
            </div>
            <label htmlFor="email" className="col-sm-3 col-form-label">
              Full Name:
            </label>
            <div className="col-sm-9">
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="As it appears on your credit card"
                value={this.state.fields.name}
                onChange={this.onChange}
              />
              {this.state.errors.name && <div className="errorMsg">{this.state.errors.name}</div>}
            </div>
          </div>

          {/* Email Field*/}
          <div className="form-group row">
            <label htmlFor="email" className="col-sm-3 col-form-label">
              Email:
            </label>
            <div className="col-sm-9">
              <input
                name="email"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.fields.email}
                onChange={this.onChange}
              />
              {this.state.errors.email && <div className="errorMsg">{this.state.errors.email}</div>}
            </div>
          </div>

          {/* Email Verification*/}
          <div className="form-group row">
            <label htmlFor="email_verification" className="col-sm-3 col-form-label">
              Verify Email:
            </label>
            <div className="col-sm-9">
              <input
                name="email_verification"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.fields.email_verification}
                onChange={this.onChange}
              />
              {this.state.errors.email_verification && (
                <div className="errorMsg">{this.state.errors.email_verification}</div>
              )}
            </div>
          </div>

          {/* Phone Verification
          <div className="form-group row">
            <label htmlFor="phone" className="col-sm-3 col-form-label">
              Phone Number:
            </label>
            <div className="col-sm-9">
              <input
                name="phone"
                type="text"
                className="form-control"
                placeholder="(123) 456-7890"
                value={this.state.fields.phone}
                onChange={this.onChange}
              />
              {this.state.errors.phone && (
                <div className="errorMsg">{this.state.errors.phone}</div>
              )}
            </div>
          </div>*/}

          {/* Phone */}
          <div className="form-group row">
            <label htmlFor="phone" className="col-sm-3 col-form-label">
              Phone Number:
            </label>
            <div className="col-sm-9">
              <PhoneInput
                placeholder={"123-456-789"}
                inputClass={"phoneInput"}
                disableDropdown={true}
                disableCountryCode={true}
                disableSearchIcon={true}
                countryCodeEditable={true}
                country={"ca"}
                buttonClass={"display:none"}
                onlyCountries={["ca"]}
                defaultMask={"...-...-...."}
                alwaysDefaultMask={true}
                value={this.state.phone}
                onChange={(phone) => {
                  let fields = this.state.fields;
                  fields.phone = this.formatPhoneNumber(phone);
                  this.setState({
                    fields,
                  });
                }}
              />

              {this.state.errors.phone && <div className="errorMsg">{this.state.errors.phone}</div>}
            </div>
          </div>

          {/* Address1 Field */}
          <div className="form-group row">
            <label htmlFor="address_line1" className="col-sm-3 col-form-label">
              Address:
            </label>
            <div className="col-sm-9">
              <input
                name="address_line1"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.fields.address_line1}
                onChange={this.onChange}
              />
              {this.state.errors.address_line1 && (
                <div className="errorMsg">{this.state.errors.address_line1}</div>
              )}
            </div>
          </div>

          {/* Address2 Field */}
          <div className="form-group row">
            <label htmlFor="address_line2" className="col-sm-3 col-form-label">
              Address 2:
            </label>
            <div className="col-sm-9">
              <input
                name="address_line2"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.fields.address_line2}
                onChange={this.onChange}
              />
            </div>
          </div>

          {/* City Field */}
          <div className="form-group row">
            <label htmlFor="address_city" className="col-sm-3 col-form-label">
              City:
            </label>
            <div className="col-sm-9">
              <input
                name="address_city"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.fields.address_city}
                onChange={this.onChange}
              />
              {this.state.errors.address_city && (
                <div className="errorMsg">{this.state.errors.address_city}</div>
              )}
            </div>
          </div>

          {/* Province Field */}
          <div className="form-group row">
            <label htmlFor="address_state" className="col-sm-3 col-form-label">
              Province:
            </label>
            <div className="col-sm-9">
              <input
                name="address_state"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.fields.address_state}
                onChange={this.onChange}
              />
              {this.state.errors.address_state && (
                <div className="errorMsg">{this.state.errors.address_state}</div>
              )}
            </div>
          </div>

          {/* Postal Field */}
          <div className="form-group row">
            <label htmlFor="address_zip" className="col-sm-3 col-form-label">
              Postal:
            </label>
            <div className="col-sm-9">
              <input
                name="address_zip"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.fields.address_zip}
                onChange={this.onChange}
              />
              {this.state.errors.address_zip && (
                <div className="errorMsg">{this.state.errors.address_zip}</div>
              )}
            </div>
          </div>

          {/* Country Field */}
          <div className="form-group row">
            <label htmlFor="address_country" className="col-sm-3 col-form-label">
              Country:
            </label>
            <div className="col-sm-9">
              <input
                name="address_country"
                type="text"
                placeholder=""
                className="form-control"
                value={this.state.fields.address_country}
                onChange={this.onChange}
              />
              {this.state.errors.address_country && (
                <div className="errorMsg">{this.state.errors.address_country}</div>
              )}
            </div>
          </div>

          {this.state.abVerify && (
            <div>
              <hr />

              <div className="form-group row">
                <div className="col-sm-12">
                  <input
                    name="confirmIndividual"
                    type="checkbox"
                    checked={this.state.fields.confirmIndividual}
                    onChange={this.toggleConfirmIndividual}
                  />{" "}
                  <span>
                    Your purchase contains rafflle tickets. Please confirm that this purchase is an
                    individual not a company.
                  </span>
                  {this.state.errors.confirmIndividual && (
                    <div className="errorMsg">{this.state.errors.confirmIndividual}</div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Card Component */}
          {!this.state.discountAvailable && (
            <div className="form-group row">
              <div className="col-sm-12">
                <div className="card p-2">
                  <CardElement onChange={(element) => this.validateCardElement(element)} />
                </div>
                <div>
                  <strong>
                    <p>Only Visa and Mastercard Accepted.</p>
                  </strong>
                </div>
                {this.state.errors.cardElement && (
                  <div className="errorMsg">{this.state.errors.cardElement}</div>
                )}
              </div>
            </div>
          )}

          {/* Reaptcha Field */}
          <div className="form-group row">
            <div className="col-sm-12">
              <Reaptcha
                sitekey={REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                onVerify={this.onVerifyCaptcha}
                onLoad={this.onLoad}
              />
              {this.state.errors.captcha && (
                <div className="errorMsg">{this.state.errors.captcha}</div>
              )}
            </div>
          </div>

          {/* Submit Button */}
          {this.state.submitButtonVisible && (
            <div className="form-group row">
              <div className="col-sm-12">
                <button className="btn btn-primary" disabled={this.state.processing}>
                  {this.state.submitButtonText}
                </button>
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  size={10}
                  color={"#3775e4"}
                  loading={this.state.processing}
                />
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form.formData,
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    { setErrorMsg, setReturnURL, setReturnButtonText }
  ),
  injectStripe,
  withRouter
);

export default enhance(CheckoutForm);
